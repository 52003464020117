export default {
    "member.removed.team.error": `\u305d\u306e\u30d2\u30c8\u306f\u30e1\u30f3\u30d0\u30fc\u304b\u3089\u524a\u9664\u3055\u308c\u3066\u3044\u307e\u3059\!`,
    "youtube.server.error": `Youtube\u306e\u30b5\u30fc\u30d0\u30fc\u304c\u5bdd\u3066\u3044\u308b\u69d8\u3067\u3059\u3002\n<US0018>`,
    "file.upload.error": `\u30d5\u30a1\u30a4\u30eb\u306e\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u306b\u5931\u6557\u3057\u307e\u3057\u305f\u3002`,
    "auth.password.error": `\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u307e\u305f\u306f\u30d1\u30b9\u30ef\u30fc\u30c9\u304c\u9055\u3046\u3088\u3046\u3067\u3059\u3002\u3002`,
    "member.already.team.error": `\u305d\u306e\u30d2\u30c8\u306f\u65e2\u306b\u30e1\u30f3\u30d0\u30fc\u3067\u3059\u3088\!`,
    "user.removed.team.error": `\u3082\u3046\u3042\u306a\u305f\u306f\u305d\u3053\u306e\u30e1\u30f3\u30d0\u30fc\u3067\u306f\u306a\u3044\u4e8b\u306b\u306a\u3063\u3066\u3044\u307e\u3059\!`,
    "auth.logon.success": `\u30ed\u30b0\u30a4\u30f3\u306b\u5927\u6210\u529f\u3057\u307e\u3057\u305f\u3002`,
    "member.admin.deleted.error": `\u305d\u306e\u4eba\u306f\u524a\u9664\u3055\u308c\u3066\u3044\u307e\u3059\u3002\u30e1\u30f3\u30d0\u30fc\u30ea\u30b9\u30c8\u306e\u66f4\u65b0\u3092\u304a\u9858\u3044\u3057\u307e\u3059\u3002`,
    "member.removed.share.error": `\u305d\u306e\u30d2\u30c8\u306f\u30de\u30c3\u30d7\u306e\u5171\u6709\u304b\u3089\u524a\u9664\u3055\u308c\u3066\u3044\u307e\u3059\!`,
    "sheet.lock.error": `\u30b7\u30fc\u30c8\u304c\u30ac\u30c3\u30c1\u30ea\u300c\u30ed\u30c3\u30af\u300d\u3055\u308c\u3066\u3044\u307e\u3059\u3002`,
    "user.access.map.all": `\u3059\u3079\u3066`,
    "user.removed.share.error": `\u3082\u3046\u3042\u306a\u305f\u306f\u305d\u306e\u30de\u30c3\u30d7\u3092\u4f7f\u3048\u306a\u3044\u4e8b\u306b\u306a\u3063\u3066\u3044\u307e\u3059\!`,
    "partner.capacity.error": `\u62db\u5f85\u6570\u304c\u4e0a\u9650\u3092\u8d85\u3048\u3066\u3044\u307e\u3059\!`,
    "user.joined.team.error": `\u3042\u306a\u305f\u306f\u307e\u3060\u30c1\u30fc\u30e0\u30e1\u30f3\u30d0\u30fc\u306a\u306e\u3067\u3001\u9000\u4f1a\u3067\u304d\u307e\u305b\u3093\u3088\uff01`,
    "map.edit.error": `\u3053\u306e\u30de\u30c3\u30d7\u306f\u7de8\u96c6\u3067\u304d\u307e\u305b\u3093\u3002`,
    "auth.approve.error": `\u4eee\u767b\u9332\u4e2d\u306e\u305f\u3081\u3001\u30ed\u30b0\u30aa\u30f3\u3059\u308b\u3053\u3068\u304c\u51fa\u6765\u307e\u305b\u3093\u3002`,
    "mail.send.error": `\u30e1\u30fc\u30eb\u9001\u4fe1\u306b\u5927\u5931\u6557\u3057\u307e\u3057\u305f\u3002\u4f55\u6545\u3067\u3057\u3087\u3046\u304b?\n<US0002>\n\u30d6\u30e9\u30a6\u30b6\u3092\u518d\u8d77\u52d5\u3057\u30ed\u30b0\u30a4\u30f3\u3057\u76f4\u3057\u3066\u307f\u3066\u4e0b\u3055\u3044\u3002\u89e3\u6c7a\u3057\u306a\u3044\u5834\u5408\u3001\u4e0a\u8a18\u300c\u30a8\u30e9\u30fc\u30b3\u30fc\u30c9\u300d\u3092\u30e1\u30fc\u30eb\u306b\u3066\u304a\u77e5\u3089\u305b\u4e0b\u3055\u3044\u3002`,
    "user.avatar.oversize.error": `\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3067\u304d\u308b\u30d5\u30a1\u30a4\u30eb\u306e\u30b5\u30a4\u30ba\u306f5MB\u307e\u3067\u3067\u3059\u3002(\u4eca\u306f)`,
    "sql.error": `\u3054\u3081\u3093\u306a\u3055\u3044\u3002\u3042\u306a\u305f\u306e\u64cd\u4f5c\u306b\u4e0a\u624b\u304f\u3064\u3044\u3066\u3086\u3051\u307e\u305b\u3093\u3067\u3057\u305f\u3002\n<US0006>\n\u30d6\u30e9\u30a6\u30b6\u3092\u518d\u8d77\u52d5\u3057\u30ed\u30b0\u30a4\u30f3\u3057\u76f4\u3057\u3066\u307f\u3066\u4e0b\u3055\u3044\u3002\u89e3\u6c7a\u3057\u306a\u3044\u5834\u5408\u3001\u4e0a\u8a18\u300c\u30a8\u30e9\u30fc\u30b3\u30fc\u30c9\u300d\u3092\u30e1\u30fc\u30eb\u306b\u3066\u304a\u77e5\u3089\u305b\u4e0b\u3055\u3044\u3002`,
    "auth.logon.error": `\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u3082\u3057\u304f\u306f\u30d1\u30b9\u30ef\u30fc\u30c9\u304c\n\u6b63\u3057\u304f\u306a\u3044\u3088\u3046\u3067\u3059\u3002`,
    "mail.regulation.error": `\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u304c\u6b63\u3057\u304f\u7121\u3044\u3088\u3046\u3067\u3059\u3002\u3054\u78ba\u8a8d\u4e0b\u3055\u3044\!`,
    "entity.conflict.error": `\u3054\u3081\u3093\u306a\u3055\u3044\u3002\u3042\u306a\u305f\u306e\u64cd\u4f5c\u306b\u4e0a\u624b\u304f\u3064\u3044\u3066\u3086\u3051\u307e\u305b\u3093\u3067\u3057\u305f\u3002\n<US0004>\n\u4e00\u5ea6\u4ed6\u30de\u30c3\u30d7\u306b\u79fb\u52d5\u3057\u3066\u307f\u308b\u304b\u3001\u30d6\u30e9\u30a6\u30b6\u3092\u518d\u8d77\u52d5\u3057\u30ed\u30b0\u30a4\u30f3\u3057\u76f4\u3057\u3066\u307f\u3066\u4e0b\u3055\u3044\u3002\u89e3\u6c7a\u3057\u306a\u3044\u5834\u5408\u3001\u4e0a\u8a18\u300c\u30a8\u30e9\u30fc\u30b3\u30fc\u30c9\u300d\u3092\u30e1\u30fc\u30eb\u306b\u3066\u304a\u77e5\u3089\u305b\u4e0b\u3055\u3044\u3002`,
    "sheet.none.error": `\u305d\u306e\u30b7\u30fc\u30c8\u306f\u4ed6\u306e\u4eba\u306b\u6d88\u3055\u308c\u3066\u3057\u307e\u3057\u307e\u3057\u305f\u3002`,
    "storage.capacity.error": `\u305d\u308c\u306f\u5927\u304d\u904e\u304e\u3066\u3001\u4eca\u3054\u5951\u7d04\u5206\u306e\u7a7a\u304d\u9818\u57df\u306b\u306f\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3067\u304d\u307e\u305b\u3093\u3002`,
    "user.exist.error": `\u30e6\u30fc\u30b6\u304c\u5b58\u5728\u3057\u307e\u305b\u3093\u3002`,
    "entity.lock.error": `\u3054\u3081\u3093\u306a\u3055\u3044\u3002\u3042\u306a\u305f\u306e\u64cd\u4f5c\u306b\u4e0a\u624b\u304f\u3064\u3044\u3066\u3086\u3051\u307e\u305b\u3093\u3067\u3057\u305f\u3002\n<US0005>\n\u4e00\u5ea6\u4ed6\u30de\u30c3\u30d7\u306b\u79fb\u52d5\u3057\u3066\u307f\u308b\u304b\u3001\u30d6\u30e9\u30a6\u30b6\u3092\u518d\u8d77\u52d5\u3057\u30ed\u30b0\u30a4\u30f3\u3057\u76f4\u3057\u3066\u307f\u3066\u4e0b\u3055\u3044\u3002\u89e3\u6c7a\u3057\u306a\u3044\u5834\u5408\u3001\u4e0a\u8a18\u300c\u30a8\u30e9\u30fc\u30b3\u30fc\u30c9\u300d\u3092\u30e1\u30fc\u30eb\u306b\u3066\u304a\u77e5\u3089\u305b\u4e0b\u3055\u3044\u3002`,
    "member.leader.removed.team.error": `\u305d\u306e\u4eba\u306f\u30e1\u30f3\u30d0\u30fc\u304b\u3089\u524a\u9664\u3055\u308c\u3066\u3044\u307e\u3059\u3002\u30e1\u30f3\u30d0\u30fc\u30ea\u30b9\u30c8\u306e\u66f4\u65b0\u3092\u304a\u9858\u3044\u3057\u307e\u3059\u3002`,
    "validate.polycy.error": `\u30d1\u30b9\u30ef\u30fc\u30c9\u306f\u534a\u89d2\u82f1\u6570\uff08\u6df7\u5728\uff09\u30678\u6587\u5b57\u4ee5\u4e0a\u306b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    "validate.polycy.password.history.error": `{0}\u4e16\u4ee3\u524d\u307e\u3067\u3068\u540c\u69d8\u306e\u30d1\u30b9\u30ef\u30fc\u30c9\u306f\u8a2d\u5b9a\u3067\u304d\u307e\u305b\u3093\u3002`,
    "sheet.capacity.error": `\u3053\u306e\u30de\u30c3\u30d7\u306b\u306f\u3001\u3082\u3046\u3053\u308c\u4ee5\u4e0a\u30b7\u30fc\u30c8\u306f\u4f5c\u308c\u307e\u305b\u3093\u3002`,
    "partner.already.team.error": `\u305d\u306e\u30d2\u30c8\u306f\u65e2\u306b\u30e1\u30f3\u30d0\u30fc\u3067\u3059\u3088\!`,
    "member.invite.other.error": `\u30a2\u30ab\u30a6\u30f3\u30c8\u306e\u767b\u9332\u306b\u5931\u6557\u3057\u305f\u3082\u306e\u304c\u3042\u308a\u307e\u3059\u3002`,
    "system.fatalerror": `\u3054\u3081\u3093\u306a\u3055\u3044\u3002\u4f55\u304b\u307e\u305a\u3044\u4e8b\u304c\u767a\u751f\u3057\u3001\u30b7\u30b9\u30c6\u30e0\u304c\u6b63\u3057\u304f\u52d5\u3044\u3066\u3044\u307e\u305b\u3093\u3002\n<US0001>\n\u30d6\u30e9\u30a6\u30b6\u3092\u518d\u8d77\u52d5\u3057\u30ed\u30b0\u30a4\u30f3\u3057\u76f4\u3057\u3066\u307f\u3066\u4e0b\u3055\u3044\u3002\u89e3\u6c7a\u3057\u306a\u3044\u5834\u5408\u3001\u4e0a\u8a18\u300c\u30a8\u30e9\u30fc\u30b3\u30fc\u30c9\u300d\u3092\u30e1\u30fc\u30eb\u306b\u3066\u304a\u77e5\u3089\u305b\u4e0b\u3055\u3044\u3002`,
    "attachfile.password.error": `\u30d1\u30b9\u30ef\u30fc\u30c9\u304c\u9593\u9055\u3063\u3066\u3044\u307e\u3059\u3002`,
    "member.admin.removed.team.error": `\u305d\u306e\u4eba\u306f\u30e1\u30f3\u30d0\u30fc\u304b\u3089\u524a\u9664\u3055\u308c\u3066\u3044\u307e\u3059\u3002\u30e1\u30f3\u30d0\u30fc\u30ea\u30b9\u30c8\u306e\u66f4\u65b0\u3092\u304a\u9858\u3044\u3057\u307e\u3059\u3002`,
    "contract.suspended.error": `\u3042\u3041\u3001\u5951\u7d04\u304c\u505c\u6b62\u3055\u308c\u3066\u3044\u307e\u3059\!\!\n<US0022>`,
    "user.avatar.capacity.error": `\u305d\u308c\u306f\u5927\u304d\u904e\u304e\u3066\u3001\u4eca\u3054\u5951\u7d04\u5206\u306e\u7a7a\u304d\u9818\u57df\u306b\u306f\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3067\u304d\u307e\u305b\u3093\u3002`,
    "template.deleted.error": `\u306a\u3093\u3068\u3001\u305d\u306e\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u306f\u524a\u9664\u3055\u308c\u3066\u3044\u307e\u3059\!\!`,
    "user.role.error": `\u6a29\u9650\u304c\u5909\u66f4\u3055\u308c\u307e\u3057\u305f\u3002\u3053\u306e\u30e6\u30fc\u30b6\u3067\u306f\u64cd\u4f5c\u3067\u304d\u307e\u305b\u3093\u3002`,
    "map.deleted.error": `\u306a\u3093\u3068\u3001\u65e2\u306b\u305d\u306e\u30de\u30c3\u30d7\u306f\u524a\u9664\u3055\u308c\u3066\u3044\u307e\u3059\!\!`,
    "user.deleted.error": `\u306a\u3093\u3068\u3001\u3042\u306a\u305f\u306f\u524a\u9664\u3055\u308c\u3066\u3044\u307e\u3059\!\!`,
    "contract.deleted.error": `\u3042\u3041\u3001\u5951\u7d04\u304c\u524a\u9664\u3055\u308c\u3066\u3044\u307e\u3059\!\!\n<US0021>`,
    "thumbnail.create.error": `\u30b5\u30e0\u30cd\u30a4\u30eb\u306e\u751f\u6210\u306b\u5931\u6557\u3057\u307e\u3057\u305f\u3002`,
    "member.capacity.error": `\u62db\u5f85\u6570\u304c\u4e0a\u9650\u3092\u8d85\u3048\u3066\u3044\u307e\u3059\!`,
    "user.avatar.notimage.error": `\u30a4\u30e1\u30fc\u30b8\u30d5\u30a1\u30a4\u30eb\u3067\u306f\u7121\u3044\u3088\u3046\u3067\u3059\u3002`,
    "member.leader.deleted.error": `\u305d\u306e\u4eba\u306f\u524a\u9664\u3055\u308c\u3066\u3044\u307e\u3059\u3002\u30e1\u30f3\u30d0\u30fc\u30ea\u30b9\u30c8\u306e\u66f4\u65b0\u3092\u304a\u9858\u3044\u3057\u307e\u3059\u3002`,
    "map.capacity.error": `\u3054\u3081\u3093\u306a\u3055\u3044\u3002\u3042\u305f\u306a\u306f\u65e2\u306b\u30de\u30c3\u30d7\u304c\u4f5c\u6210\u4e0a\u9650\u3092\u8d85\u3048\u308b\u305f\u3081\u3001\u4f5c\u6210\u3067\u304d\u307e\u305b\u3093\u3002`,
    "session.timeout": `セッションタイムアウトしました。ログインし直して下さい。\n<MS0003>`,
    "attachfile.oversize.error": `\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3067\u304d\u308b\u30d5\u30a1\u30a4\u30eb\u306e\u30b5\u30a4\u30ba\u306f100MB\u307e\u3067\u3067\u3059\u3002`,
    "password.wrong.error": `\u30d1\u30b9\u30ef\u30fc\u30c9\u304c\u9593\u9055\u3063\u3066\u3044\u308b\u3088\u3046\u3067\u3059\u304c\u3001\u5927\u4e08\u592b\u3067\u3059\u304b?`,
    "member.already.share.error": `\u305d\u306e\u30d2\u30c8\u306f\u65e2\u306b\u5171\u6709\u3055\u308c\u3066\u3044\u307e\u3059\u3088\!`,
    "member.deleted.error": `\u305d\u306e\u30d2\u30c8\u306fK-Board\u4e16\u754c\u304b\u3089\u524a\u9664\u3055\u308c\u3066\u3044\u307e\u3059\!`,
    "auth.contract.error": `\u5bfe\u8c61\u306e\u5951\u7d04\u304c\u3042\u308a\u307e\u305b\u3093\u3002`,
    "user.company.notbelong.partner.error": `\u4f01\u696d\u30d1\u30fc\u30c8\u30ca\u30fc\u306b\u6240\u5c5e\u3057\u3066\u3044\u307e\u305b\u3093\u3002`,
    "user.company.notbelong.member.error": `\u4f01\u696d\u30e1\u30f3\u30d0\u30fc\u306b\u6240\u5c5e\u3057\u3066\u3044\u307e\u305b\u3093\u3002`,
    "auth.suspend.error": `\u30e6\u30fc\u30b6\u30a2\u30ab\u30a6\u30f3\u30c8\u306f\u505c\u6b62\u3055\u308c\u3066\u3044\u307e\u3059\u3002`,
    "team.name.duplicated.error": `\u30c1\u30fc\u30e0\u540d\u304c\u91cd\u8907\u3057\u3066\u767b\u9332\u3055\u308c\u3066\u3044\u307e\u3059\u3002 - {0}`,
    "team.characters.over.error": `\u30c1\u30fc\u30e0\u540d\u306f{0}\u6587\u5b57\u4ee5\u5185\u3067\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    "ipad.license.error": `iPad\u30a2\u30af\u30bb\u30b9\u30e9\u30a4\u30bb\u30f3\u30b9\u304c\u3042\u308a\u307e\u305b\u3093\u3002`,
    "ip.access.error": `\u3053\u306e\u30c1\u30fc\u30e0\u306fIP\u30a2\u30c9\u30ec\u30b9\u304c\u5236\u9650\u3055\u308c\u3066\u3044\u307e\u3059\u3002`,
    "template.moved.error": `\u81ea\u793e\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u3068\u5171\u901a\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u9593\u3067\u306e\u79fb\u52d5\u306f\u3067\u304d\u307e\u305b\u3093\u3002`,
    "template.to.map.create.error": `\u4ed6\u4f01\u696d\u3078\u306e\u30de\u30c3\u30d7\u306e\u4f5c\u6210\u306f\u3067\u304d\u307e\u305b\u3093\u3002`
};
