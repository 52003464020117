import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from './wlp-client-resource/locales/en_US';
import translationJA from './wlp-client-resource/locales/ja_JP';
import wlpMessagesEN from './wlp-client-resource/locales/wlpMessages_en_US.properties';
import wlpMessagesJA from './wlp-client-resource/locales/wlpMessages_ja_JP.properties';
import translationJACustom from './wlp-client-resource/locales/ja_JP_custom';
import WlpLanguageUtil from "wlp-client-common/utils/WlpLanguageUtil";
import corpLanguage from "wlp-client-corp-lib/locales/ja_JP";

const languageUtils = new WlpLanguageUtil();


// the translations
const langEn = Object.assign({}, translationEN, wlpMessagesEN, corpLanguage);
const langJa = Object.assign({}, translationJA, wlpMessagesJA, translationJACustom, corpLanguage);


const resources = {
    'en_US': {
        translation: langEn
    },
    'ja_JP': {
        translation: langJa
    },
};

const lookup = 'localeCode';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: resources,
        lng: languageUtils.getCurrentLanguage() || languageUtils.getDefaultLanguage(),
        fallbackLng: languageUtils.getDefaultLanguage(),

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        detection: {
            lookupLocalStorage: lookup,
            lookupCookie: lookup
        }
    });

export default i18n;