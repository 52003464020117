export default {
    'global.title.manager': '企業 Manager',
    'selelect.yes': '有り',
    'selelect.no_one' : '無し',

    'update_success':'変更しました',
    'account.id': 'ID',
    'account.email': 'メールアドレス',
    'account.nickname': 'ニックネーム',
    'account.surname': '姓名',
    'account.status': '状態',
    'account.authority': '権限',
    'account.manager1': '管理者１',
    'account.administrator2': '管理者２',
    'account.iPadAllocation': 'iPad割当',
    'account.numberOfTeam': '所属チーム数',
    'account.corporateWithdrawal': '企業退会',
    'account.freeField': 'フィールド',
    'account.freeField1': 'フリーフィールド1',
    'account.freeField2': 'フリーフィールド2',
    'account.freeField3': 'フリーフィールド3',
    'account.freeField4': 'フリーフィールド4',
    'account.freeField5': 'フリーフィールド5',
    'account.mailMultipleStatsItem': 'メールアドレス',
    'account.messageMultipleStatsItem': 'メッセージ',
    'account.memberAccountStatsItem': 'メンバーアカウント',
    'account.partnerAccountStatsItem': 'パートナーアカウント',
    'account.memberRadtioButton': 'メンバー',
    'account.partnerRadtioButton': 'パートナー',
    'account.accountAdressStatsItem': 'メールアドレス',
    'account.messageStatsItem': 'メッセージ',
    'account.addAccountLabel': 'アカウントの追加',
    'account.addAccountMessage1': '複数のアカウントをまとめて登録します。1 行 1 メールアドレスで入力して下さい。( 5,000 文字以内 )',
    'account.addAccountMessage2': 'なお、メールアドレスの後ろをカンマで区切ることにより、アカウントのフリーフィールド 1 から 5 に値を登録する事ができます。',
    'account.addAccountMessage3': '例：フリーフィールド 1 から 5 に A から E までの値を登録。',
    'account.addAccountMessage4': 'メールアドレス,A,B,C,D,E',
    'account.registerSuccessMessage': 'アカウントへ招待メールを送信しました。',
    'account.registerErrorMessage': 'メールアドレスを入力してください。',
    'account.backButton': '戻る',
    'account.btnSearch': '検索',
    'account.accountRegistrationStatus': 'アカウントの登録状況',
    'account.accountStatsItem': 'アカウントの登録状況',
    'account.accountChange': '変更',
    'account.registerAccountButton': 'アカウント登録',
    'account.mailMultipleStatsItemMessage': 'メールアドレスが無効です。',
    'account.numberOfIpadStatsItem': 'iPad数(割当/全体)',
    'account.numberOfMember':'人',
    'account.downloadAccountButton': 'アカウントCSVダウンロード',

    'team.email': 'メールアドレス',
    'team.teamName': 'チーム名称',
    'team.resgitration': 'チーム登録',
    'team.csvRegistration': 'チーム CSV 登録',
    'team.searchBtn': '検索',
    'team.id': 'ID',
    'team.name': 'チーム名称',
    'team.leaderMailAddress': 'リーダーメールアドレス',
    'team.status': '状態',
    'team.searchStatus': '検索状況',
    'team.storageUsageRate': 'ストレージ使用率 (%)',
    'team.numberOfMembers': 'メンバー数',
    'team.numberOfPartners': 'パートナー数',
    'team.lastModified': '最終更新日時',

    'other.contactForSupport': '「退会」につきましては、弊社サポートメールにてお問い合わせください。',
    'other.emailSupport': 'サポートメールアドレス',
    'other.emailSupportAddress': 'support@accelatech.com',
    'other.ipAddressSetting': 'IPアドレス制限設定',
    'other.ipAddressSettingExplain': 'この企業マネージャーにアクセスできるIPアドレスの制限設定です。',
    'other.ipControlRadioButtonGroupNo': '設定しない（デフォルト）',
    'other.ipControlRadioButtonGroupYes': '設定する',
    'other.ipMulTipleStatItem': ' 接続可能とするIPアドレスを、半角数字で、1行単位に入力して下さい 。(記入例 ***.***.***.***)',
    'other.ipMulTipleStatItemError': 'IP Address is invalid',
    'other.registerIpButton': '設定',
    'other.messageRegisterSuccess': 'IPアドレス制限の設定をしました。',
    'other.messageError': 'エラーが発生しました。',
    'general.message.error': 'エラーが発生しました。',

    'login.saveCorpIdCheckBox': '企業 Manager ID を記憶する',
    'login.adminMailaddressTextInput': '管理者メールアドレス',
    'login.adminPasswordTextInput': '管理者パスワード',
    'login.loginButton': 'Login',
    'login.tooManyFailedLoginAttempts': '５回連続でログインに失敗すると、企業 Manager ID がロックアウトされます',
    'login.contactForSupport': 'ロックアウトを解除するには、下記メールアドレスまでご連絡ください',
    'login.emailSupportAddress': 'support@accelatech.com',
    
    'registerTeam.registerSearchTeamStatusFalse': '非公開',
    'registerTeam.registerSearchTeamStatusTrue': '公開',
    'registerTeam.detailTeamNameTextInput': 'チーム名',
    'registerTeam.detailSearchTeamStatusComboBox': '検索',
    'registerTeam.leaderMailaddress': 'リーダーメールアドレス',
    'registerTeam.transitRegisterTeamButton': 'チーム登録',
    'registerTeam.registerMessageTextInputSuccess': 'リーダーへ招待メールを送信しました。',
    'registerTeam.validate.detailTeamNameTextInput': 'チーム名を入力してください。',
    'team.btnTeamCSVRegistration': 'チームCSV登録',
    'team.btnTeamRegistration': 'チーム登録', 
    'team.failedGetCSV': 'CSV の取得に失敗しました',
    'team.registTeamListCSVSuccess': 'メンバーへ招待メールを送信しました',
    'team.teamIdGridColumn': 'ID',
    'team.teamNameGridColumn': 'チーム名称',
    'team.leaderMailaddressGridColumn': 'リーダーメールアドレス',
    'team.statusGridColumn': '状態',
    'team.searchStatusGridColumn': '検索状況',
    'team.strageUsageGridColumn': 'ストレージ使用率 (%)',
    'team.memberTotalGridColumn': 'メンバー数',
    'team.partnerTotalGridColumn': 'パートナー数',
    'team.lastUpdateTimeGridColumn': '最終更新日時',
    'team.mailAddressTextLable': 'メールアドレス',
    'team.teamNameTextLable': 'チーム名称',
    'team.searchTeamButton': '検索',
    'team.ipLimitToAccessTeam': 'このチームにアクセスできるIPアドレスの制限設定です。',
    'team.ipAddressIsInvalid':'IP Address is invalid.',
    'login.error':'ログインエラー',
    'login.failures.has.exceeded.5': 'ログイン失敗回数が 5 回を超えました。',
    
    'emergency.all.team.suspended': '全チーム一時停止',
    'emergency.team':'チーム',
    'emergency.suspended.all.teams': 'すべてのチームを一時停止しました',
    'emergency.status.of.team.usage': 'チーム利用状況',
    'emergency.active.team': '利用中チーム',
    'emergency.suspended.team': '一時停止チーム',
    'emegency.unpause.all.teams':'全チーム一時停止解除',
    'emergency.warring.paused':'全チーム一時停止します。\nよろしいですか？',
    'emergency.warring.to.paused':'全チーム一時停止解除します。\nよろしいですか？',
    'emergency.unpaused.all.teams': 'すべてのチームを一時停止解除しました',
    'emergency.dialog.cancel': 'Cancel',
    'emergency.dialog.ok': 'O K',
    'emergency.dialog.submit': 'Submit',
    'emergency.dialog.input.password': 'Enter your password...',
    'emergency.password': 'Password',
    'team.messageError': 'エラーが発生しました。',
    'team.back': '戻る',
    'team.member.and.partner.registration.status': 'メンバーとパートナーの登録状況',
    'team.number.of.members': 'メンバー数',
    'team.per.team': '（１チームあたり）',
    'team.number.of.partners': 'パートナー数',
    'team.maximum.number.in.the.team.map': '（チームマップ中の最大数）',
    'team.member.list': 'メンバーリスト',
    'team.add.member': 'メンパーの追加',
    'team.egister.multiple.members.at.once': '複数のメンバーをまとめて登録します。',
    'team.please.enter.one.email.per.line': '1行1メールアドレスで入力して下さい',
    'team.up.to.5000.characters': '(5,000文字以内)',
    'team.mail.address': 'メールアドレス',
    'team.email.MulTipleStatItemError': 'メールアドレスが無効です。',
    'team.message': 'メッセージ',
    'team.button.add.member': 'メンバー追加',
    'team.message.add.member.success':'メンバーを追加しました。',
    'team.please.enter.your.e-mail.address.': 'メールアドレスを入力してください。',

    'team.team.suspended': 'チームを一時停止しました',
    'team.the.team.has.been.stopped': 'チーム停止を解除しました',

    'footer.license': 'COPYRIGHT Accela Technology Corporation. ALL RIGHTS RESERVED.',
    'logout': 'Logout',

    'header.tooltipAdmin': 'Admin user',
    'header.tooltipAdminPrivilege': 'Privilege admin user',

    'footer.copyright': 'COPYRIGHT Accela Technology Corporation. ALL RIGHTS RESERVED.',

    'usage.people.per.team': '人 ( 1 チームあたり )',
    'usage.title.content.contract': '契約内容',
    'usage.package.name': '申込プラン',
    'usage.storage.total': 'ストレージ容量',
    'usage.member.total': '招待数上限',
    'usage.company.id': '企業 ID',
    'usage.start.date': '利用開始日',
    'usage.mail.address1': '管理者１メールアドレス',
    'usage.mail.address2': '管理者２メールアドレス',
    'usage.company.name': '企業名',
    'usage.billing.department.name':'管理担当部署名',
    'usage.tel.no': '電話番号',
    'usage.address':'住所',
    'usage.overall': '全体',
    'usage.number.of.teams': 'チーム数',
    'usage.number.of.members': 'メンバー数',
    'usage.number.of.partners': 'パートナー数',
    'usage.number.of.Ipad':'iPad数(割当/全体)',
    'usage.number.of.maps': 'マップ数',
    'usage.number.of.sheets': 'シート数',
    'usage.number.of.items': 'アイテム数',
    'usage.number.of.base.items': 'ベースアイテム数',
    'usage.number.of.attach.files': '添付ファイル数',
    'usage.total.of.attach.file.size': 'ストレージ使用量（MB)',
    'usage.storage.usage': 'ストレージ使用率（％）',

    'accountDetail.personalID': '個人ID',
    'accountDetail.alertFilterSuccess': '変更しました',
    'accountDetail.creationDate': '作成日時',
    'accountDetail.lastLoginDate': '最終ログイン日時',
    'accountDetail.status': '状態',
    'accountDetail.mailAddress': 'メールアドレス',
    'accountDetail.timeZone': 'タイムゾーン',
    'accountDetail.language': '言語',
    'accountDetail.field1': 'フィールド1',
    'accountDetail.field2': 'フィールド2',
    'accountDetail.field3': 'フィールド3',
    'accountDetail.field4': 'フィールド4',
    'accountDetail.field5': 'フィールド5',
    'accountDetail.remark': '備考',
    'accountDetail.team': '所属チーム',
    'accountDetail.updateAccountButton': '変更',
    'accountDetail.messageError': 'エラーが発生しました。',
    'accountDetail.backFromDetailButton': 'Back',
    'account.regist.limit': '企業に一度に登録できるアカウント数は100までです。',

    'teamDetail.detailTeamNamelabelText': 'チーム名',
    'teamDetail.detailStatsStatsItem': '状態',
    'teamDetail.detailSearchTeamStatusComboBoxLabelText': '検索',
    'teamDetail.detailNumAttachFilesStatsItem': '添付ファイル数',
    'teamDetail.detailStorageUsageStatsItem': 'ストレージ容量 (MB)',
    'teamDetail.detailStorageUtilizationStatsItem': 'ストレージ使用率 (%)',
    'teamDetail.detailTeamIdStatsItem': 'チーム ID',
    'teamDetail.detailNumMapsStatsItem': 'マップ数',
    'teamDetail.detailNumSheetsStatsItem': 'シート数',
    'teamDetail.detailNumItemsStatsItem': 'アイテム数',
    'teamDetail.detailCreateDateStatsItem': '作成日時',
    'teamDetail.detailNumMembersStatsItem': 'メンバー数',
    'teamDetail.detailNumPartersStatsItem': 'パートナー数',
    'teamDetail.detailIpAccessControlStatsItem': 'IP制限',
    'teamDetail.confirmDeleteTeamMessage': 'チームを削除すると、チームのマップもすべて削除され、元に戻せなくなります。チームを削除しますか？',
    'teamDetail.confirmDeleteUserMessage': 'チームから削除しても宜しいですか？\n（この操作は取り消しできません）',
    
    
    'teamDetail.detailStopTeamButton': 'チーム一時停止',
    'teamDetail.detailDelteTeamButton': 'チーム削除',
    'teamDetail.detailCancelStopTeamButton': 'チーム停止解除',
    'teamDetail.detailUpdateTeamButton': '更新',
    'teamDetail.detailLeaderGridColumn': 'リーダー',
    'teamDetail.detailFacilitatorGridColumn': 'ファシリテーター',
    'teamDetail.detailDeleteFlagGridColumn': 'チームから削除',
    'teamDetail.detailAddTeamMemberButton': 'メンバー追加',
    'teamDetail.detailRegisterIpAddressButton': 'IP制限設定',
    'teamDetail.suppendTeamMessage': 'チームを一時停止します。\nよろしいですか？',
    'teamDetail.cancelSuppendTeamMessage': 'チーム停止を解除します。\nよろしいですか？',
    'team.add.limit': '度に登録できるチーム数は100個までです。',
    'team.add.null': '利用開始されていません。ライセンスの確定を行ってください。',
    'team.add.not.csv': '指定されたファイルはCSVファイルではありません。',
    'team.add.csv.validate.teamName': 'チーム名が空欄のものがあります。チーム名を入力してください。'

}