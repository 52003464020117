import { LANGUAGE_KEY_MAP } from 'wlp-client-common/consts/languageConsts';
import LocalStorageUtils from './LocalStorageUtils';
import i18n from 'i18n';

const localStorageUtil = new LocalStorageUtils();

class WlpLanguageUtil {

    constructor(){
        this.keyMap = Object.keys(LANGUAGE_KEY_MAP);
    }

    getDefaultLanguage(){
        for(const langueKey of this.keyMap){
            const langueValue = LANGUAGE_KEY_MAP[langueKey];
            //find browser language in list support language
            if(langueValue.indexOf(navigator.language) > -1){
                return langueKey;
            }
        }

        //if browser language is not valid => return default language;
        return this.keyMap[0];
    }

    getLanguaeByLocaleCode(code){
        for(const langueKey of this.keyMap){
            const langueValue = LANGUAGE_KEY_MAP[code];
            //find provided language code in list support language
            if(langueValue.indexOf(navigator.language) > -1){
                return langueKey;
            }
        }

        //if provided language code is not valid => return default language;
        return this.keyMap[0];
    }

    getCurrentLanguage(){
        return localStorageUtil.getLocalStrage(localStorageUtil.STRAGE_LOCALE_CODE);
    }

    getLookupKey(){
        return localStorageUtil.STRAGE_LOCALE_CODE;
    }

    setLocaleLanguage(lang){
        i18n.changeLanguage(lang);
        localStorageUtil.setLocalStrage(localStorageUtil.STRAGE_LOCALE_CODE, lang);
    }
}

export default WlpLanguageUtil;