import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";

import PrealoadDisplay from 'wlp-client-common/component/PreloadDisplay';
const CorpMain = React.lazy(() => import('wlp-client-corp/Main'));

const Main = () => {
    return (
        <React.Suspense fallback={<PrealoadDisplay />}>
            <Router>
                <Switch>
                    <Route path="/corp">
                        <CorpMain />
                    </Route>
                    <Redirect exact from="/" to="/corp" />
                </Switch>
            </Router>
        </React.Suspense>
    )
}

export default Main;