class LocalStorageUtils {

    SKIP_TOUR_EDITOR = "skipTourEditor";

    SKIP_TOUR_MYPAGE = "skipTourMypage";

    TEAM_COLOR = "teamColor";

    TEAM_ORDER = "teamOrder";

    RECENT_MAPS = "recentMap";

    LOCAL_STORAGE = "kakiage";

    STRAGE_NAME = "mailaddress";

    STRAGE_LOCALE_CODE = "localeCode";

    SET_COOKIE = 'set-cookie-string';

    TEAM_DATA = 'teamData';

    ADD_MEMBER_METHOD = 'selectOptionAddMember';

    SESSION_KEY = 'uss';

    HIDE_LOGOUT = true;

    MAP_USER_INFO = "mapUserInfo";

    DOWNLOAD_RESULT = "downloadResult";
    
    IP_ADDRESS = "ipddress"

    CURRENT_COMPANY_ID = "current_company_id"
    
    setCompanyId(value){
        this.setLocalStrage(this.CURRENT_COMPANY_ID, value);
    }
    getCompanyId(){
        return this.getLocalStrage(this.CURRENT_COMPANY_ID);
    }

    setHideLogout(value){
        this.setLocalStrage(this.HIDE_LOGOUT, value);
    }
    getHideLogout(){
        return this.getLocalStrage(this.HIDE_LOGOUT);
    }
    
    setLocalStrage(strageName, dataObject) {
        localStorage.setItem(strageName, JSON.stringify(dataObject));
    }

    setUserSession(session){
        this.setLocalStrage(this.SESSION_KEY, session);
    }

    getUserSession(){
        return this.getLocalStrage(this.SESSION_KEY);
    }

    deleteUserSession(){
        localStorage.removeItem(this.SESSION_KEY);
    }

    getLocalStrage(strageName) {
        const strageData = localStorage.getItem(strageName);
        try {
            return JSON.parse(strageData);
        } catch (e) {
            return strageData;
        }
    }

    getLocaleCode(){
        return this.getLocalStrage(this.STRAGE_LOCALE_CODE);
    }

    getLocalStrageItem(strageName) {
        var strageData = localStorage.getItem(strageName);
        if (strageData) {
            return strageData;
        } else {
            return null;
        }
    }

    deleteLocalStrage(strageName) {
        localStorage.removeItem(strageName);
    }

    setAccessToken(token){
        this.setLocalStrage('accessToken', token);
    }

    getAccessToken(){
        return this.getLocalStrage('accessToken');
    }

    setSaveIpAddress(value){
        this.setLocalStrage(this.IP_ADDRESS, value);
    }

    getSaveIpAddress(){
        return this.getLocalStrage(this.IP_ADDRESS);
    }

}
export default LocalStorageUtils;