export default ({
    'message.global.e0001': `Sorry, something's gone wrong and our system is not working properly.\n<UC0001>\nRestart your browser and try logging in again.\nIf the problem persists, please send the above error code in an email to us.`,
    'message.global.e0002': `Are you sure your email address is correct?`,
    'message.global.c0001': `Really?`,
    'message.global.c0002': `If you do not log out to exit the menu, etc. may not terminate properly locked item becomes a period of time.`,
    'message.global.c0003': `Are you sure you want to logout?`,
    'message.login.e0001': `Please enter your email address.`,
    'message.login.e0002': `Please enter your password.`,
    'message.login.e0003': `Please enter your current password.`,
    'message.login.i0001': `Your password has been changed.`,
    'message.login.i0002': `Changed the password.`,
    'message.maps.e0001': `Please enter map name.`,
    'message.maps.c0001': `Are you sure you want to remove member from sharing?`,
    'message.maps.c0002': `Are you sure you want to remove partner from sharing?`,
    'message.maps.c0003': `Are you sure you want to remove user from sharing?`,
    'message.maps.c0004': `Are you sure you want to delete this map and all of its contents?`,
    'message.templates.e0001': `Please enter title.`,
    'message.templates.e0002': `Please enter publisher.`,
    'message.templates.e0003': `Please enter author.`,
    'message.templates.c0001': `Are you sure you want to delete this template?`,
    'message.personal.e0001': `You can upload files up to 5MB in size. (For the time being.)`,
    'message.personal.e0002': `The password you entered is incorrect.`,
    'message.personal.e0003': `For security reasons, your password must be a minimum of 8 alphanumeric characters long(mixtured).`,
    'message.personal.e0004': `Please enter your current password.`,
    'message.personal.e0005': `Please enter your last name.`,
    'message.personal.e0006': `Please enter your first name.`,
    'message.personal.e0007': `Please enter your first and last name.`,
    'message.personal.e0008': `File upload failed.\n<UC0023>`,
    'message.personal.c0001': `If you delete this account, all maps you have created will be deleted. Are you sure you wish to continue? Are you really sure?`,
    'message.team.e0001': `This email address is already in use.`,
    'message.team.e0002': `Please enter a team name which best suits your goals.`,
    'message.team.c0001': `Are you sure you want to remove this member?`,
    'message.team.c0002': `Are you sure you want to modify member authorities?`,
    'message.team.c0003': `If you delete this team account, all maps you have created as a team will be deleted. Are you sure you wish to continue? Are you really sure?`,
    'global.ok': `O K`,
    'global.cancel': `CANCEL`,
    'global.leader': `Leader`,
    'global.admin': `Facilitator`,
    'global.member': `Member`,
    'global.owner': `Owner`,
    'global.partner': `Partner`,
    'global.inviting': `Inviting`,
    'global.errorLabel': `ERROR`,
    'global.headerMenu.logout': `Logout`,
    'global.configMenu.help': `FAQ`,
    'global.configMenu.shortcut': `Shortcut List`,
    'global.configMenu.upgrade': `Upgrading the License`,
    'global.configMenu.aboutkboard': `About K-Board`,
    'global.flashPlayer.upgrade': `The version of Flash Player, we recommend upgrading to the latest version.`,
    'login.login.login': `Login`,
    'login.login.password': `password`,
    'login.login.savemailaddress': `Save mail address`,
    'login.login.mailaddress': `mail addresses`,
    'login.login.or': `or`,
    'login.login.newaccount': `New Account`,
    'login.login.fogotpassword': `Forgot password?`,
    'login.forgotpassword.title': `Please input the E-mail address at the time of account creation, and push a transmitting button. <br>I will send the guidance about a re-setup of a password.`,
    'login.changepassword.title': `Your password has expired and must be changed.`,
    'login.forgotpassword.send': `send`,
    'login.forgotpassword.back': `back login`,
    'login.message': `Some <a href="http://register.kakiage.jp/agreement/" class="color-aqua" target="_blank">terms of service</a> were also changed from October 1, 2015.<br>Please make sure to check the new one before using it.`,
    'mypage.headerMenu.personalSettings': `Personal Settings`,
    'mypage.listLabel.teams': `TEAMS`,
    'mypage.listLabel.templates': `TEMPLATES`,
    'mypage.maps.createNewMap': `NEW MAP`,
    'mypage.maps.mapKind.all': `ALL`,
    'mypage.maps.mapKind.recent': `RECENT MAPS`,
    'mypage.maps.mapKind.bookmarks': `BOOKMARKS`,
    'mypage.maps.list.operation.openMap': `Open the Map`,
    'mypage.maps.list.operation.rename': `Rename`,
    'mypage.maps.list.operation.duplicate': `Make a Copy`,
    'mypage.maps.list.operation.delete': `Delete`,
    'mypage.maps.preview.mapTitle': `Name`,
    'mypage.maps.preview.teamName': `Team`,
    'mypage.maps.preview.popular': `Popularity`,
    'mypage.maps.preview.size': `Size`,
    'mypage.maps.preview.created': `Created on`,
    'mypage.maps.preview.owner': `Created by`,
    'mypage.maps.preview.lastModified': `Updated on`,
    'mypage.maps.preview.updateUserName': `Updated by`,
    'mypage.maps.preview.operation.saveTemplate': `Save as Template`,
    'mypage.maps.preview.share.header': `Sharing`,
    'mypage.maps.preview.share.memberShared': `Members sharing`,
    'mypage.maps.preview.share.addableMember': `You can add members`,
    'mypage.maps.preview.share.partnerShared': `Partners sharing`,
    'mypage.maps.preview.share.addableUserNumber': `You can add`,
    'mypage.maps.preview.share.userUnit': `people`,
    'mypage.maps.preview.share.invitePartner': `Invite Partner to Map`,
    'mypage.maps.preview.share.sendCopyToMe': `Send copy of invite email to yourself`,
    'mypage.maps.preview.share.sharedWith': `Currently sharing`,
    'mypage.maps.preview.share.inviteUser': `Invite to Map`,
    'mypage.maps.preview.share.mailInputGuide': `Email address`,
    'mypage.maps.preview.share.optionalMessage': `Add Message`,
    'mypage.maps.preview.share.private': `undo sharing`,
    'mypage.maps.preview.share.excommunication': `undo sharing`,
    'mypage.maps.preview.ipControl': `access control`,
    'mypage.maps.preview.ipControl.on': `ON`,
    'mypage.maps.preview.ipControl.off': `OFF`,
    'mypage.maps.preview.share.inviteMaxPartner': `You have invited the maximum number of partners to this map.`,
    'mypage.maps.preview.share.inviteMaxUser': `You have invited the maximum number of users to this map.`,
    'mypage.maps.preview.share.shareUpgrade': `If you want to share a map with more people, please click here to upgrade.`,
    'mypage.maps.titleInput.rename': `Rename`,
    'mypage.maps.titleInput.create': `New Map`,
    'mypage.maps.titleInput.duplicate': `Make a Copy`,
    'mypage.maps.titleInput.createFromTemplate.title': `Create Map from Template`,
    'mypage.maps.titleInput.createFromTemplate.message': `This will be copied to the map list.`,
    'mypage.templates.preview.templateTitle': `Name`,
    'mypage.templates.preview.category': `Category`,
    'mypage.templates.preview.author': `Created by`,
    'mypage.templates.preview.publisher': `Published by`,
    'mypage.templates.preview.releaseDate': `Released`,
    'mypage.templates.list.operation.createMap': `Create map from this template`,
    'mypage.templates.list.operation.changeInfo': `Update template information`,
    'mypage.templates.list.operation.delete': `Delete`,
    'mypage.templates.infoInput.title.new': `New template`,
    'mypage.templates.infoInput.title.update': `Update template`,
    'mypage.templates.infoInput.title.prompt': `Template name`,
    'mypage.templates.infoInput.publisher.prompt': `Published by`,
    'mypage.templates.infoInput.author.prompt': `Created by`,
    'mypage.templates.infoInput.detail.prompt': `Details`,
    'mypage.personalSettings.title': `Personal Settings`,
    'mypage.personalSettings.back': `Return to Map Overview`,
    'mypage.personalSettings.userInfomation': `User Information`,
    'mypage.personalSettings.password': `Password`,
    'mypage.personalSettings.serviceConnections': `External Services`,
    'mypage.personalSettings.accountStatus': `Account Status`,
    'mypage.personalSettings.user.changePicture': `Select preset image`,
    'mypage.personalSettings.user.upload': `Upload user image`,
    'mypage.personalSettings.user.lastLogin': `Last login`,
    'mypage.personalSettings.user.menberSince': `Member History`,
    'mypage.personalSettings.user.nickname': `Nickname`,
    'mypage.personalSettings.user.firstName': `First name`,
    'mypage.personalSettings.user.lastName': `Last name`,
    'mypage.personalSettings.user.companyName': `Company name`,
    'mypage.personalSettings.user.busyo': `Department`,
    'mypage.personalSettings.user.gyosyu': `Industry`,
    'mypage.personalSettings.user.syokusyu': `Position`,
    'mypage.personalSettings.user.telno': `Phone number`,
    'mypage.personalSettings.user.email': `E-mail`,
    'mypage.personalSettings.user.language': `Language Settings`,
    'mypage.personalSettings.user.timezone': `Time zone`,
    'mypage.personalSettings.user.save': `Save Changes`,
    'mypage.personalSettings.user.saveMessage': `Changes saved`,
    'mypage.personalSettings.changePicture.title': `Select preset image`,
    'mypage.personalSettings.password.title': `Change Password`,
    'mypage.personalSettings.password.currentPassword': `Current Password`,
    'mypage.personalSettings.password.enterCurrentPassword': ``,
    'mypage.personalSettings.password.passComment': `Your password must contain at least 8 characters (mixtured).`,
    'mypage.personalSettings.password.newPassword': `New password`,
    'mypage.personalSettings.password.enterNewPassword': ``,
    'mypage.personalSettings.password.reEnterNewPassword': `Confirm new password`,
    'mypage.personalSettings.password.saveMessage': `Changes saved`,
    'mypage.personalSettings.serviceConnections.connect': `Connect`,
    'mypage.personalSettings.serviceConnections.evetnoteDetail': `Itemize all text, images and web clips (URL) in your Evernotes and make flexible use of them in K-Board world!`,
    'mypage.personalSettings.serviceConnections.twitterDetail': `Take a tweet from your timeline and try out 'Tweet Evolution' in K-Board!`,
    'mypage.personalSettings.serviceConnections.dropboxDetail': `Try sorting the files in your Dropbox with K-Board's "Mass Reference Filer" tool!`,
    'mypage.personalSettings.accountStatus.usage.title': `Usage`,
    'mypage.personalSettings.accountStatus.usage.disk.title': `Disk space`,
    'mypage.personalSettings.accountStatus.usage.disk.max': `Total Space :`,
    'mypage.personalSettings.accountStatus.usage.disk.use': `\u3000Used Space :`,
    'mypage.personalSettings.accountStatus.usage.map.title': `Map`,
    'mypage.personalSettings.accountStatus.usage.map.unit': ``,
    'mypage.personalSettings.accountStatus.usage.map.max': `Maximum Maps :`,
    'mypage.personalSettings.accountStatus.usage.map.use': `\u3000Used Maps :`,
    'mypage.personalSettings.accountStatus.upgrade.title': `Upgrade`,
    'mypage.personalSettings.accountStatus.upgrade.comments': `When you upgrade your K-Board account,your storage capacity will increase considerably and you will have unlimited map building access. \nMake the most of the K-Board world!`,
    'mypage.personalSettings.accountStatus.upgrade.btnLbl': `Details of the plan`,
    'mypage.personalSettings.accountStatus.delete.btnLbl': `Withdraw`,
    'mypage.personalSettings.accountStatus.delete.after.title': `Thank you for using K-Board.`,
    'mypage.personalSettings.accountStatus.team.title': `Team`,
    'mypage.personalSettings.accountStatus.team.name': `Team name`,
    'mypage.personalSettings.accountStatus.team.role': `Role`,
    'mypage.personalSettings.accountStatus.team.map': `Available maps`,
    'mypage.teamSettings.back': `Return to Map Overview`,
    'mypage.teamSettings.teamInfomation': `Team Information`,
    'mypage.teamSettings.memberList': `Member List`,
    'mypage.teamSettings.addMembers': `Add Members`,
    'mypage.teamSettings.accountStatus': `Account Status`,
    'mypage.teamSettings.team.createDate': `Team created on`,
    'mypage.teamSettings.team.teamName': `Team name`,
    'mypage.teamSettings.team.companyName': `Company name`,
    'mypage.teamSettings.team.about.title': `About your team`,
    'mypage.teamSettings.team.about.prompt': `Please write an introduction for your team.`,
    'mypage.teamSettings.team.save': `Save Changes`,
    'mypage.teamSettings.team.saveMessage': `Changes saved`,
    'mypage.teamSettings.member.header.user': `USER`,
    'mypage.teamSettings.member.header.mapNum': `NUMBER OF MAPS`,
    'mypage.teamSettings.member.header.leader': `LEADER`,
    'mypage.teamSettings.member.header.admin': `FACILITATOR`,
    'mypage.teamSettings.member.mapUnit': `maps`,
    'mypage.teamSettings.member.deleteBtnLbl': `Delete`,
    'mypage.teamSettings.member.save': `Save Changes`,
    'mypage.teamSettings.member.saveMessage': `Changes saved`,
    'mypage.teamSettings.addMember.usage.title': `Usage`,
    'mypage.teamSettings.addMember.usage.user.title': `User`,
    'mypage.teamSettings.addMember.usage.user.unit': ``,
    'mypage.teamSettings.addMember.usage.user.max': `Maximum Member-Invitations :`,
    'mypage.teamSettings.addMember.usage.user.use': `\u3000Member registered :`,
    'mypage.teamSettings.addMember.member.title': `Add Members`,
    'mypage.teamSettings.addMember.member.comments': `Please enter the email address[es] of the user[s] you wish to invite to the team.`,
    'mypage.teamSettings.addMember.member.batchComments': `Please enter one email address of the user you wish to invite to the team per line. (up to 5000 characters)`,
    'mypage.teamSettings.addMember.member.prompt': `Email address`,
    'mypage.teamSettings.addMember.member.add': `Add`,
    'mypage.teamSettings.addMember.member.complete': `Invite sent.`,
    'mypage.teamSettings.addMember.member.maxMessage': `You have added the maximum number of people.`,
    'mypage.teamSettings.addMember.member.maxMessageOption': `Delete users from the Member List before adding new members.`,
    'mypage.teamSettings.addMember.invite.btnLbl': `Invite`,
    'mypage.teamSettings.addMember.invite.individual': `Individual`,
    'mypage.teamSettings.addMember.invite.batch': `Batch`,
    'mypage.teamSettings.accountStatus.usage.title': `Usage`,
    'mypage.teamSettings.accountStatus.usage.disk.title': `Disk space`,
    'mypage.teamSettings.accountStatus.usage.disk.max': `Total Space :`,
    'mypage.teamSettings.accountStatus.usage.disk.use': `\u3000Used Space :`,
    'mypage.teamSettings.accountStatus.usage.user.title': `User`,
    'mypage.teamSettings.accountStatus.usage.user.unit': ``,
    'mypage.teamSettings.accountStatus.usage.user.max': `Maximum Member-Invitations :`,
    'mypage.teamSettings.accountStatus.usage.user.use': `\u3000Member registered :`,
    'mypage.teamSettings.accountStatus.delete.btnLbl': `Withdraw`,
    'mypage.afterDeleting.title': `Thank you for using K-Board.`,
    'mypage.questionnaire.title': `Withdraw (remove account)`,
    'mypage.questionnaire.termination.title': `Please tell us why you're leaving us. (You may select multiple check boxes)`,
    'mypage.questionnaire.reason.title': `I was unhappy with`,
    'mypage.questionnaire.reason.kakaku': `Price`,
    'mypage.questionnaire.reason.strage': `Storage capacity`,
    'mypage.questionnaire.reason.attachment': `Size limit on attachments`,
    'mypage.questionnaire.reason.exService': `Linkage with external services (EVERNOTE, DropBox, YouTube etc.)`,
    'mypage.questionnaire.reason.os': `OS Compatibility`,
    'mypage.questionnaire.reason.browser': `Browser Compatibility`,
    'mypage.questionnaire.reason.security': `Security`,
    'mypage.questionnaire.reason.item': `Variations in items and linetypes`,
    'mypage.questionnaire.reason.thumb': `Thumbnail and preview functions`,
    'mypage.questionnaire.reason.slow': `Speed`,
    'mypage.questionnaire.reason.instability': `Stability`,
    'mypage.questionnaire.reason.noSynchro': `Synchronization`,
    'mypage.questionnaire.reason.ability': `Number of functions`,
    'mypage.questionnaire.reason.functionalityDesired': `please indicate a function you'd like`,
    'mypage.questionnaire.use.title': `Did not suit the following tasks`,
    'mypage.questionnaire.use.conference': `Meeting support`,
    'mypage.questionnaire.use.email': `Email alternative`,
    'mypage.questionnaire.use.organize': `Individual mind-mapping`,
    'mypage.questionnaire.use.understand': `I did not understand how to use it`,
    'mypage.questionnaire.others.title': `Other`,
    'mypage.questionnaire.others.switched': `I've changed to a different provider`,
    'mypage.questionnaire.others.switchedServiceName': `Please indicate the name of provider you are currently using`,
    'mypage.questionnaire.others.trouble': `I had problems with another user`,
    'mypage.questionnaire.others.lessFrequently': `I used it with decreasing frequency`,
    'mypage.questionnaire.environment.title': `Please tell us about your environment.`,
    'mypage.questionnaire.os.title': `Which OS did you mainly use?`,
    'mypage.questionnaire.os.win': `Windows`,
    'mypage.questionnaire.os.mac': `Mac`,
    'mypage.questionnaire.os.others': `Other`,
    'mypage.questionnaire.browser.title': `Which browser did you mainly use?`,
    'mypage.questionnaire.browser.ie': `Internet Explorer`,
    'mypage.questionnaire.browser.firefox': `Firefox`,
    'mypage.questionnaire.browser.chrome': `Google Chrome`,
    'mypage.questionnaire.browser.safari': `Safari`,
    'mypage.questionnaire.browser.others': `Other`,
    'mypage.questionnaire.userNum.title': `Did you mainly use it alone?`,
    'mypage.questionnaire.userNum.one': `Mainly alone`,
    'mypage.questionnaire.userNum.multi': `Mainly in a group`,
    'mypage.questionnaire.option': `Please let us know If you have any comments or suggestions for improvement.`,
    'mypage.questionnaire.option.prompt': `Free entry`,
    'mypage.questionnaire.lastMessage': `Thank you for your feedback.`,
    'mypage.questionnaire.confirm': `If you remove this account, all maps you have created will be deleted.`,
    'mypage.information.title': `Information from K-Board`,
    'mypage.tour.skipTour': `This mypage explaination screen will not display from next time.`,
    'search.prompt.editor': `Search for Item on Map`,
    'search.prompt.mypage': `Search in teams you join`,
    'search.maps': `Maps`,
    'search.sheets': `Sheets`,
    'search.items': `Items`,
    'search.map': `Map`,
    'search.sheet': `Sheet`,
    'search.item': `Item`,
    'search.team': `Team`,
    'search.owner': `Owner`,
    'search.author': `Author`,
    'search.showAllResults': `Show all results`,
    'search.lastUpdated': `Updated on`,
    'search.back': `Back`,
    'search.noResults1': `No results found.`,
    'search.noResults2': `Check your spelling or try another keyword.`,
    'editor.header.sheet.label': `Worksheet`,
    'editor.header.sheet.create': `Add a new Worksheet`,
    'editor.header.sheet.copy': `Copy the current Worksheet`,
    'editor.header.sheet.png': `Save as PNG format`,
    'editor.header.sheet.pngbyselected': `Save the selected section as PNG format`,
    'editor.header.sheet.jpeg': `Save as JPEG format`,
    'editor.header.sheet.csv': `Save as CSV format`,
    'editor.header.sheet.remove': `Delete the current Worksheet`,
    'editor.header.sheet.lock': `Lock the current Worksheet`,
    'editor.header.sheet.goodshow': `Display 'Good'`,
    'editor.header.sheet.goodshort': `Smaller 'Good' Display`,
    'editor.header.sheet.forcedvisiblenewitem': `Show higlight New Item`,
    'editor.header.sheet.memo': `Show Notes`,
    'editor.header.sheet.lockBaseItem': `Lock the current Baseitem`,
    'editor.header.sheet.property': `Worksheet property`,
    'editor.header.edit.label': `Edit`,
    'editor.header.edit.createItem': `Create Item`,
    'editor.header.edit.createItemFromDoc': `Create new Items from document`,
    'editor.header.edit.createBaseitem': `Create Baseitem`,
    'editor.header.edit.copy': `Copy`,
    'editor.header.edit.cut': `Cut`,
    'editor.header.edit.paste': `Paste`,
    'editor.header.edit.remove': `Delete`,
    'editor.header.display.label': `Display`,
    'editor.header.display.navigator': `Display Navigator`,
    'editor.header.display.presence': `Display attendance status`,
    'editor.header.display.goodshow': `Display 'Good'`,
    'editor.header.display.forcedvisiblenewitem': `Highlight the latest Items`,
    'editor.header.display.forcedvisiblenewitemBefore2D': `Highlight the last 2 days Items`,
    'editor.header.display.forcedvisiblenewitemBefore6D': `Highlight the last 6 days Items`,
    'editor.header.display.showPrintingRangeGuide': `Display printing range guide`,
    'editor.newItem.label': `New item`,
    'editor.newBaseItem.label': `New baseitem`,
    'editor.navigator.title': `Navigator`,
    'editor.navigator.buttonLabel': `ShowAll`,
    'editor.newItemLine.label': `Label`,
    'editor.option.label': `Option`,
    'editor.option.sortUpdateDate': `Sort by Upload Date`,
    'editor.option.sortTitle': `Sort by Title`,
    'editor.option.removeAll': `Delete All`,
    'editor.memo.label.title': `CamiApp allows you to directly upload notes too!`,
    'editor.memo.label.description': `Download CamiApp here`,
    'editor.memo.label.download': `Official Website Link`,
    'editor.memo.link.ios': `\u30fbiPhone ver`,
    'editor.memo.link.android': `\u30fbAndroid ver`,
    'editor.memo.renderer.menu.item': `Itemize`,
    'editor.memo.renderer.menu.delete': `Delete`,
    'editor.attach.clearPassword': `Are you sure you wish to remove your password?`,
    'editor.external.tooltip': `Double-click to create item`,
    'editor.twitter.reload': `Refresh Tweets`,
    'editor.twitter.error': `Please login to use Twitter in K-Board`,
    'editor.twitter.login': `\u2192 Login to Twitter`,
    'editor.evernote.notebook': `Notebook`,
    'editor.evernote.createNewItemFromWebClip': `Create New Items from Webclip`,
    'editor.evernote.createNewItemFromText': `Create New Item from Text`,
    'editor.evernote.error': `Please sign in to use Evernote in K-Board`,
    'editor.evernote.login': `\u2192 Sign in to Evernote`,
    'editor.dropbox.back': `Up One`,
    'editor.dropbox.error': `Please login to use Dropbox in K-Board`,
    'editor.dropbox.login': `\u2192 Login to Dropbox`,
    'editor.youtube.search': `Search for Video`,
    'editor.youtube.all': `All`,
    'editor.youtube.topRated': `Most Liked Videos`,
    'editor.youtube.topFavirites': `Top Favorited Videos`,
    'editor.youtube.mostViewed': `Most Viewed videos`,
    'editor.youtube.mostPopular': `Popular Videos`,
    'editor.youtube.mostRecent': `New Videos`,
    'editor.youtube.mostDiscused': `Most Discussed Videos`,
    'editor.youtube.mostResponded': `Most Commented Videos`,
    'editor.youtube.recentlyFeatured': `Recommended`,
    'editor.youtube.uploader': `User :`,
    'editor.youtube.viewCount': `views :`,
    'editor.sheetProperty.sheetProperty': `Worksheet Properties`,
    'editor.sheetProperty.background': `Background`,
    'editor.sheetProperty.createDate': `Created :`,
    'editor.sheetProperty.updateDate': `Updated :`,
    'editor.sheetCopy.keywordPrompt': `Search for Map`,
    'editor.sheetCopy.message1': `Make a copy of the sheet.`,
    'editor.sheetCopy.message2': `This sheet will be added to the end the map it was copied to.`,
    'editor.itemPropertiy.base.itemLabel': `Item property`,
    'editor.itemPropertiy.base.baseItemLabel': `Baseitem Properties`,
    'editor.itemPropertiy.base.createDate': `Created on :`,
    'editor.itemPropertiy.base.updateDate': `Last updated :`,
    'editor.itemPropertiy.attachfile.label': `Attachment file`,
    'editor.itemPropertiy.attachfile.download': `Download attachment file`,
    'editor.itemPropertiy.attachfile.imageOpen': `Download attachment image`,
    'editor.itemPropertiy.attachfile.name': `File name :`,
    'editor.itemPropertiy.attachfile.type': `Type :`,
    'editor.itemPropertiy.attachfile.size': `Size :`,
    'editor.itemPropertiy.password.enter': `Enter password`,
    'editor.itemPropertiy.password.reEnter': `Re-enter password`,
    'editor.itemPropertiy.password.entered': `Password : *******`,
    'editor.itemPropertiy.password.setting': `Setting password`,
    'editor.itemPropertiy.password.remove': `Remove password`,
    'editor.link.buttonLabel': `Share`,
    'editor.link.snapshot.create': `View Worksheet Snapshot`,
    'editor.link.snapshot.reflesh': `Update Snapshot`,
    'editor.link.snapshot.copy': `Copy image URL`,
    'editor.link.sheetUrl': `Worksheet URL`,
    'editor.link.sheetUrl.copy': `Copy Sheet URL`,
    'editor.link.sendSheetUrl': `Send URL to Shared Users`,
    'editor.link.messagePrompt': `Add Message`,
    'editor.link.clear': `Clear`,
    'editor.link.send': `Send`,
    'editor.link.complete': `Successfully sent the mail.`,
    'editor.lock.buttonLabel': `Sheet locked`,
    'editor.itemCreateAssist.create': `Create Item`,
    'editor.itemCreateAssist.prompt': `Enter text (up to 8000 characters 100 items)`,
    'editor.analogical.TitleLabel': `Related items were found.`,
    'editor.analogical.noLabel': `There are no items that match the criteria.`,
    'editor.itemMenu.shape': `Pattern alteration`,
    'editor.itemMenu.style': `Color alteration`,
    'editor.itemMenu.text': `Character\u2019s size, text position alteration`,
    'editor.itemMenu.download': `Download a file`,
    'editor.itemMenu.file': `Upload a file\n(Attn: The text will be replaced by the uploaded file )`,
    'editor.itemMenu.externalSource': `Get data from SNS`,
    'editor.itemMenu.property': `Properties\n(To set a password)`,
    'editor.tour.skipTour': `Don't display this instruction again`,
    'editor.c0001': `Are you really sure you wish to delete Worksheet?`,
    'editor.c0002': `Really?`,
    'editor.c0003': `Virus-check failed, for a possible reason of encryption. Are you sure to upload it?`,
    'editor.c0004': `Virus-check failed, for a possible reason of encryption. Downloading it can be risky. Are you sure to download it?`,
    'editor.e0001': `Failed to communicate.`,
    'editor.e0002': `Reload the page to get the latest sheet information.`,
    'editor.e0003': `A virus was found. In case of devastating consequence happens, file uploading has to be terminated.`,
    'editor.w0001': `Can not upload files larger than 100MB.`,
    'editor.w0002': `Error exporting the file.`,
    'editor.w0003': `Error updaload the file.`,
    'editor.w0004': `cannot upload the file that "~" is included in a file name.`,
    'mypage.slider.next': `Next`,
    'mypage.slider.previous': `Previous`,
    'mypage.slider.button.now': `Now K-Board`,
})