export default {
    "member.removed.team.error": `This person has been removed as a member\!`,
    "youtube.server.error": `The YouTube server seems to be down.\n<US0018>`,
    "file.upload.error": `Failed upload.`,
    "auth.password.error": `Your email address or password is incorrect.`,
    "member.already.team.error": `This person is already a member\!`,
    "user.removed.team.error": `You are no longer a member\!`,
    "auth.logon.success": `Login successful.`,
    "member.admin.deleted.error": `This person has been removed. Please update the member list.`,
    "member.removed.share.error": `This person has been removed from map sharing\!`,
    "sheet.lock.error": `This worksheet is locked.`,
    "user.access.map.all": `All maps`,
    "user.removed.share.error": `You can no longer use this map\!`,
    "partner.capacity.error": `You have reached your limit on invites.`,
    "user.joined.team.error": `You're still a team member, so you can't withdraw\!`,
    "map.edit.error": `You can't edit this map.`,
    "auth.approve.error": `Not Approved.`,
    "mail.send.error": `Message sending failed. How could this be?\n<US0002>\nRestart your browser and try logging in again. If the problem persists, please send the above error code in an email to us.`,
    "user.avatar.oversize.error": `You can upload files up to 5MB in size. (for the time being)`,
    "sql.error": `Sorry, we were unable to process your request at this time.\n<US0006>\nRestart your browser and try logging in again. If the problem persists, please send the above error code in an email to us.`,
    "auth.logon.error": `Excuse me,<br>Incorrect user ID or password.`,
    "mail.regulation.error": `The email address you entered is incorrect. Please double-check\!`,
    "entity.conflict.error": `Sorry, we were unable to process your request at this time.\n<US0004>\nTry switching to a different map, or restart your browser and try logging in again. If the problem persists, please send the above error code in an email to us.`,
    "sheet.none.error": `This worksheet has been deleted by someone else.`,
    "storage.capacity.error": `You do not currently have enough free space to upload that on your account.`,
    "user.exist.error": `User not exist.`,
    "entity.lock.error": `Sorry, we were unable to process your request at this time.\n<US0005>\nTry switching to a different map, or restart your browser and try logging in again. If the problem persists, please send the above error code in an email to us.`,
    "member.leader.removed.team.error": `This person has been removed as a member. Please update the member list.`,
    "validate.polycy.error": `Your password must contain at least 8 characters (mixtured).`,
    "validate.polycy.password.history.error": `{0} times before password cannot be used again.`,
    "sheet.capacity.error": `You do not create any more sheets in this map.`,
    "partner.already.team.error": `This person is already a member\!`,
    "member.invite.other.error": `Cannot create account.`,
    "system.fatalerror": `Sorry, something's gone wrong and our system is not working properly.\n<US0001>\nRestart your browser and try logging in again. If the problem persists, please send the above error code in an email to us.`,
    "attachfile.password.error": `Password is wrong.`,
    "member.admin.removed.team.error": `This person has been removed as a member. Please update the member list.`,
    "contract.suspended.error": `Oh no, this account has been suspended\!\!\n<US0022>`,
    "user.avatar.capacity.error": `You do not currently enough free space to upload that on your account.`,
    "template.deleted.error": `Uh oh, this template has been removed\!\!`,
    "user.role.error": `You do not have permission to operate.`,
    "map.deleted.error": `Uh oh, that map has already been removed\!\!`,
    "user.deleted.error": `Uh oh, you've been removed\!\!`,
    "contract.deleted.error": `Oh no, this account has been removed\!\!\n<US0021>`,
    "thumbnail.create.error": `Failed to create thumbnail.`,
    "member.capacity.error": `You have reached your limit on invites.`,
    "user.avatar.notimage.error": `No image files.`,
    "member.leader.deleted.error": `This person has been removed. Please update the member list.`,
    "map.capacity.error": `Sorry, you have reached the map building limit and cannot create any more maps.`,
    "session.timeout": `Your session has timed out. Please log in again. \n<MS0003>`,
    "attachfile.oversize.error": `The size of file you can upload up to 100MB.`,
    "password.wrong.error": `The password you entered is incorrect.`,
    "member.already.share.error": `This person is already sharing\!`,
    "member.deleted.error": `This person has been removed from K-Board world\!`,
    "auth.contract.error": `Not contract.`,
    "user.company.notbelong.partner.error": `The user does not belong to a company partner.`,
    "user.company.notbelong.member.error": `The user does not belong to a company member.`,
    "auth.suspend.error": `This user has been suspended.`,
    "team.name.duplicated.error": `Team name [{0}] is duplicated.`,
    "team.characters.over.error": `Please team name input {0} characters or less.`,
    "ipad.license.error": `You don't have iPad License.`,
    "ip.access.error": `The IP address of this team is limited.`,
    "template.moved.error": `Sorry, you cannot do that.`,
    "template.to.map.create.error": `Sorry, you cannot do that.`
};
