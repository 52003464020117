export default ({
    'message.global.e0001': `\u3054\u3081\u3093\u306a\u3055\u3044\u3002\u4f55\u304b\u307e\u305a\u3044\u4e8b\u304c\u767a\u751f\u3057\u3001\u30b7\u30b9\u30c6\u30e0\u304c\u6b63\u3057\u304f\u52d5\u3044\u3066\u3044\u307e\u305b\u3093\u3002\n<UC0001>\n\u30d6\u30e9\u30a6\u30b6\u3092\u518d\u8d77\u52d5\u3057\u30ed\u30b0\u30a4\u30f3\u3057\u76f4\u3057\u3066\u307f\u3066\u4e0b\u3055\u3044\u3002\n\u89e3\u6c7a\u3057\u306a\u3044\u5834\u5408\u3001\u4e0a\u8a18\u300c\u30a8\u30e9\u30fc\u30b3\u30fc\u30c9\u300d\u3092\u30e1\u30fc\u30eb\u306b\u3066\u304a\u77e5\u3089\u305b\u4e0b\u3055\u3044\u3002`,
    'message.global.e0002': `\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u3001\u9055\u3063\u3066\u307e\u305b\u3093\u304b?`,
    'message.global.c0001': `\u672c\u5f53\u3067\u3059\u304b?`,
    'message.global.c0002': `\u30e1\u30cb\u30e5\u30fc\u304b\u3089\u30ed\u30b0\u30a2\u30a6\u30c8\u3092\u62bc\u3057\u3066\u7d42\u4e86\u3057\u306a\u3044\u3068\u3001\n\u4e00\u5b9a\u6642\u9593\u30a2\u30a4\u30c6\u30e0\u304c\u30ed\u30c3\u30af\u3055\u308c\u305f\u72b6\u614b\u306b\u306a\u308b\u306a\u3069\n\u6b63\u3057\u304f\u7d42\u4e86\u3057\u306a\u3044\u5834\u5408\u304c\u3042\u308a\u307e\u3059\u3002`,
    'message.global.c0003': `\u30ed\u30b0\u30a2\u30a6\u30c8\u3057\u307e\u3059\u3002\u3088\u308d\u3057\u3044\u3067\u3059\u304b\uff1f`,
    'message.login.e0001': `\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'message.login.e0002': `\u30d1\u30b9\u30ef\u30fc\u30c9\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'message.login.e0003': `\u0070\u006c\u0065\u0061\u0073\u0065 \u0065\u006e\u0074\u0065\u0072 \u0079\u006f\u0075\u0072 \u0063\u0075\u0072\u0072\u0065\u006e\u0074 \u0070\u0061\u0073\u0073\u0077\u006f\u0072\u0064\u002e`,
    'message.login.i0001': `\u30d1\u30b9\u30ef\u30fc\u30c9\u518d\u8a2d\u5b9a\u306e\u3054\u6848\u5185\u30e1\u30fc\u30eb\u3092\u304a\u9001\u308a\u3057\u307e\u3057\u305f\u3002`,
    'message.login.i0002': `\u30d1\u30b9\u30ef\u30fc\u30c9\u3092\u5909\u66f4\u3057\u307e\u3057\u305f\u3002`,
    'message.maps.e0001': `\u30de\u30c3\u30d7\u540d\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'message.maps.c0001': `\u30e1\u30f3\u30d0\u30fc\u306e\u5171\u6709\u3092\u89e3\u9664\u3057\u307e\u3059\u3002\u3088\u308d\u3057\u3044\u3067\u3059\u304b\uff1f`,
    'message.maps.c0002': `\u30d1\u30fc\u30c8\u30ca\u30fc\u306e\u5171\u6709\u3092\u89e3\u9664\u3057\u307e\u3059\u3002\u3088\u308d\u3057\u3044\u3067\u3059\u304b\uff1f`,
    'message.maps.c0003': `\u30e6\u30fc\u30b6\u30fc\u306e\u5171\u6709\u3092\u89e3\u9664\u3057\u307e\u3059\u3002\u3088\u308d\u3057\u3044\u3067\u3059\u304b\uff1f`,
    'message.maps.c0004': `\u30de\u30c3\u30d7\u3092\u524a\u9664\u3057\u307e\u3059\u3002\u30de\u30c3\u30d7\u5185\u306e\u30c7\u30fc\u30bf\u306f\u5168\u3066\u524a\u9664\u3055\u308c\u307e\u3059\u3002\n\u3088\u308d\u3057\u3044\u3067\u3059\u304b?\u672c\u5f53\u3067\u3059\u304b?`,
    'message.templates.e0001': `\u30bf\u30a4\u30c8\u30eb\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'message.templates.e0002': `\u767a\u884c\u5143\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'message.templates.e0003': `\u4f5c\u8005\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'message.templates.c0001': `\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u3092\u524a\u9664\u3057\u307e\u3059\u3002\n\u3088\u308d\u3057\u3044\u3067\u3059\u304b?\u672c\u5f53\u3067\u3059\u304b?`,
    'message.personal.e0001': `\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3067\u304d\u308b\u30d5\u30a1\u30a4\u30eb\u306e\u30b5\u30a4\u30ba\u306f5MB\u307e\u3067\u3067\u3059\u3002(\u4eca\u306f)`,
    'message.personal.e0002': `\u5165\u529b\u3055\u308c\u305f\u30d1\u30b9\u30ef\u30fc\u30c9\u304c\u6b63\u3057\u304f\u306a\u3044\u3088\u3046\u3067\u3059\u304c\u3001\u3001`,
    'message.personal.e0003': `\u5b89\u5168\u306e\u70ba\u3001\u624b\u5805\u304f8\u6587\u5b57\u4ee5\u4e0a\u306e\u534a\u89d2\u82f1\u6570\uff08\u6df7\u5728\uff09\u3067\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'message.personal.e0004': `\u73fe\u5728\u306e\u30d1\u30b9\u30ef\u30fc\u30c9\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'message.personal.e0005': `\u59d3\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'message.personal.e0006': `\u540d\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'message.personal.e0007': `\u59d3\u3068\u540d\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'message.personal.e0008': `\u30d5\u30a1\u30a4\u30eb\u306e\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u306b\u5927\u5931\u6557\u3057\u307e\u3057\u305f\u3002\n<UC0023>`,
    'message.personal.c0001': `\u3053\u306e\u30a2\u30ab\u30a6\u30f3\u30c8\u3092\u524a\u9664\u3059\u308b\u3068\u3001\u3042\u306a\u305f\u304c\u4f5c\u6210\u3057\u305f\u30de\u30c3\u30d7\u306f\u5168\u3066\u524a\u9664\u3055\u308c\u307e\u3059\u3002\u3088\u308d\u3057\u3044\u3067\u3059\u304b\uff1f\u672c\u5f53\u3067\u3059\u304b?`,
    'message.team.e0001': `\u65e2\u306b\u5165\u529b\u6e08\u307f\u306e\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u3067\u3059!`,
    'message.team.e0002': `\u76ee\u7684\u306b\u30d4\u30c3\u30bf\u30ea\u306e\u30c1\u30fc\u30e0\u540d\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'message.team.c0001': `\u30e1\u30f3\u30d0\u30fc\u3092\u524a\u9664\u3057\u307e\u3059\u3002\u3088\u308d\u3057\u3044\u3067\u3059\u304b?`,
    'message.team.c0002': `\u30e1\u30f3\u30d0\u30fc\u306e\u6a29\u9650\u3092\u5909\u66f4\u3057\u307e\u3059\u3002\u3088\u308d\u3057\u3044\u3067\u3059\u304b?`,
    'message.team.c0003': `\u3053\u306e\u30c1\u30fc\u30e0\u30a2\u30ab\u30a6\u30f3\u30c8\u3092\u524a\u9664\u3059\u308b\u3068\u3001\u30c1\u30fc\u30e0\u3067\u4f5c\u6210\u3055\u308c\u305f\u30de\u30c3\u30d7\u306f\u5168\u3066\u524a\u9664\u3055\u308c\u307e\u3059\u3002\u3088\u308d\u3057\u3044\u3067\u3059\u304b\uff1f\u672c\u5f53\u3067\u3059\u304b?`,
    'global.ok': `O K`,
    'global.cancel': `\u30ad\u30e3\u30f3\u30bb\u30eb`,
    'global.leader': `\u30ea\u30fc\u30c0\u30fc`,
    'global.admin': `\uff8c\uff67\uff7c\uff98\uff83\uff70\uff80\uff70`,
    'global.member': `\u30e1\u30f3\u30d0\u30fc`,
    'global.owner': `\u30aa\u30fc\u30ca\u30fc`,
    'global.partner': `\u30d1\u30fc\u30c8\u30ca\u30fc`,
    'global.inviting': `\u62db\u5f85\u4e2d`,
    'global.errorLabel': `\u30a8\u30e9\u30fc`,
    'global.headerMenu.logout': `\u30ed\u30b0\u30a2\u30a6\u30c8`,
    'global.configMenu.help': `FAQ`,
    'global.configMenu.shortcut': `\u30b7\u30e7\u30fc\u30c8\u30ab\u30c3\u30c8\u4e00\u89a7`,
    'global.configMenu.upgrade': `\u30e9\u30a4\u30bb\u30f3\u30b9\u306e\u30a2\u30c3\u30d7\u30b0\u30ec\u30fc\u30c9`,
    'global.configMenu.aboutkboard': `K-Board\u306b\u3064\u3044\u3066`,
    'global.flashPlayer.upgrade': `Flash Player\u306e\u30d0\u30fc\u30b8\u30e7\u30f3\u304c\u53e4\u3044\u305f\u3081\u3001\u6700\u65b0\u7248\u3078\u306e\u30a2\u30c3\u30d7\u30b0\u30ec\u30fc\u30c9\u3092\u304a\u52e7\u3081\u3057\u307e\u3059\u3002`,
    'login.login.login': `\u30ed\u30b0\u30a4\u30f3`,
    'login.login.mailaddress': `\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9`,
    'login.login.savemailaddress': `\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u3092\u8a18\u9332\u3059\u308b`,
    'login.login.password': `\u30d1\u30b9\u30ef\u30fc\u30c9`,
    'login.login.or': `\u307e\u305f\u306f`,
    'login.login.newaccount': `\u65b0\u898f\u30a2\u30ab\u30a6\u30f3\u30c8\u3092\u4f5c\u6210`,
    'login.login.fogotpassword': `\u30d1\u30b9\u30ef\u30fc\u30c9\u3092\u304a\u5fd8\u308c\u3067\u3059\u304b\uff1f`,
    'login.forgotpassword.title': `\u30a2\u30ab\u30a6\u30f3\u30c8\u4f5c\u6210\u6642\u306eE\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u3092\u5165\u529b\u3057\u9001\u4fe1\u30dc\u30bf\u30f3\u3092\u62bc\u3057\u3066\u4e0b\u3055\u3044\u3002\n\u30d1\u30b9\u30ef\u30fc\u30c9\u306e\u518d\u8a2d\u5b9a\u306b\u3064\u3044\u3066\u306e\u3054\u6848\u5185\u3092\u304a\u9001\u308a\u3057\u307e\u3059\u3002`,
    'login.changepassword.title': `\u30d1\u30b9\u30ef\u30fc\u30c9\u306e\u671f\u9650\u304c\u5207\u308c\u307e\u3057\u305f\u3002\u65b0\u3057\u3044\u30d1\u30b9\u30ef\u30fc\u30c9\u3092\u8a2d\u5b9a\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'login.forgotpassword.send': `\u9001 \u4fe1`,
    'login.forgotpassword.back': `\u623b \u308b`,
    'login.message': `2015\u5e7410\u67081\u65e5\u3088\u308a<a href="http://register.kakiage.jp/agreement/" class="color-aqua" target="_blank">\u5229\u7528\u898f\u7d04</a>\u304c\u5909\u66f4\u3055\u308c\u307e\u3057\u305f\u3002<br>\u5fc5\u305a\u5fa1\u78ba\u8a8d\u30fb\u4e86\u627f\u306e\u4e0a\u3001\u5fa1\u5229\u7528\u9802\u304d\u307e\u3059\u69d8\u3001<br>\u5b9c\u3057\u304f\u5fa1\u9858\u3044\u7533\u3057\u4e0a\u3052\u307e\u3059\u3002`,
    'mypage.headerMenu.personalSettings': `\u500b\u4eba\u8a2d\u5b9a`,
    'mypage.listLabel.teams': `\u30c1\u30fc\u30e0`,
    'mypage.listLabel.templates': `\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8`,
    'mypage.maps.createNewMap': `\u65b0\u898f\u30de\u30c3\u30d7`,
    'mypage.maps.mapKind.all': `\u3059\u3079\u3066`,
    'mypage.maps.mapKind.recent': `\u6700\u8fd1\u958b\u3044\u305f\u30de\u30c3\u30d7`,
    'mypage.maps.mapKind.bookmarks': `\u30d6\u30c3\u30af\u30de\u30fc\u30af`,
    'mypage.maps.list.operation.openMap': `\u958b \u304f`,
    'mypage.maps.list.operation.rename': `\u30de\u30c3\u30d7\u540d\u306e\u5909\u66f4`,
    'mypage.maps.list.operation.duplicate': `\u8907 \u88fd`,
    'mypage.maps.list.operation.delete': `\u524a \u9664`,
    'mypage.maps.preview.mapTitle': `\u30de\u30c3\u30d7\u540d`,
    'mypage.maps.preview.teamName': `\u30c1\u30fc\u30e0\u540d`,
    'mypage.maps.preview.popular': `\u4eba\u6c17\u5ea6`,
    'mypage.maps.preview.size': `\u30b5\u30a4\u30ba`,
    'mypage.maps.preview.created': `\u65b0\u898f\u4f5c\u6210`,
    'mypage.maps.preview.owner': `\u4f5c\u6210\u8005`,
    'mypage.maps.preview.lastModified': `\u6700\u7d42\u66f4\u65b0`,
    'mypage.maps.preview.updateUserName': `\u66f4\u65b0\u8005`,
    'mypage.maps.preview.ipControl': `IP\u5236\u9650`,
    'mypage.maps.preview.ipControl.on': `\u6709\u308a`,
    'mypage.maps.preview.ipControl.off': `\u7121\u3057`,
    'mypage.maps.preview.operation.saveTemplate': `\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u3068\u3057\u3066\u4fdd\u5b58`,
    'mypage.maps.preview.share.header': `\u5171\u6709`,
    'mypage.maps.preview.share.memberShared': `\u5171\u6709\u4e2d\u306e\u30e1\u30f3\u30d0\u30fc`,
    'mypage.maps.preview.share.addableMember': `\u8ffd\u52a0\u53ef\u80fd\u306a\u30e1\u30f3\u30d0\u30fc`,
    'mypage.maps.preview.share.partnerShared': `\u5171\u6709\u4e2d\u306e\u30d1\u30fc\u30c8\u30ca\u30fc`,
    'mypage.maps.preview.share.addableUserNumber': `\u8ffd\u52a0\u53ef\u80fd\u4eba\u6570`,
    'mypage.maps.preview.share.userUnit': `\u4eba`,
    'mypage.maps.preview.share.invitePartner': `\u30d1\u30fc\u30c8\u30ca\u30fc\u3092\u30de\u30c3\u30d7\u306b\u62db\u5f85`,
    'mypage.maps.preview.share.sendCopyToMe': `\u62db\u5f85\u30e1\u30fc\u30eb\u306e\u30b3\u30d4\u30fc\u3092\u81ea\u5206\u306b\u3082\u9001\u308b`,
    'mypage.maps.preview.share.sharedWith': `\u5171\u6709\u4e2d`,
    'mypage.maps.preview.share.inviteUser': `\u30de\u30c3\u30d7\u306b\u62db\u5f85`,
    'mypage.maps.preview.share.mailInputGuide': `\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9`,
    'mypage.maps.preview.share.optionalMessage': `\u8ffd\u52a0\u30e1\u30c3\u30bb\u30fc\u30b8`,
    'mypage.maps.preview.share.private': `\u5171\u6709\u3092\u89e3\u9664`,
    'mypage.maps.preview.share.excommunication': `\u5171\u6709\u3092\u89e3\u9664`,
    'mypage.maps.preview.share.inviteMaxPartner': `\u30de\u30c3\u30d7\u306b\u62db\u5f85\u3067\u304d\u308b\u30d1\u30fc\u30c8\u30ca\u30fc\u306e\u6570\u304c\u4e0a\u9650\u306b\u9054\u3057\u307e\u3057\u305f\u3002`,
    'mypage.maps.preview.share.inviteMaxUser': `\u30de\u30c3\u30d7\u306b\u62db\u5f85\u3067\u304d\u308b\u4eba\u6570\u304c\u4e0a\u9650\u306b\u9054\u3057\u307e\u3057\u305f\u3002`,
    'mypage.maps.preview.share.shareUpgrade': `\u3088\u308a\u591a\u304f\u306e\u4eba\u3068\u30de\u30c3\u30d7\u3092\u5171\u6709\u3057\u305f\u3044\u5834\u5408\u306f\u3053\u3061\u3089\u304b\u3089\u30a2\u30c3\u30d7\u30b0\u30ec\u30fc\u30c9\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'mypage.maps.preview.tamplate.header': `\u4f7f\u7528\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8`,
    'mypage.maps.preview.tamplate.category': `\u30ab\u30c6\u30b4\u30ea\u30fc`,
    'mypage.maps.preview.tamplate.author': `\u4f5c\u8005`,
    'mypage.maps.preview.tamplate.releaseDate': `\u30ea\u30ea\u30fc\u30b9\u65e5`,
    'mypage.maps.titleInput.rename': `\u30de\u30c3\u30d7\u540d\u306e\u5909\u66f4`,
    'mypage.maps.titleInput.create': `\u65b0\u898f\u30de\u30c3\u30d7\u540d`,
    'mypage.maps.titleInput.duplicate': `\u8907\u88fd\u30de\u30c3\u30d7\u540d`,
    'mypage.maps.titleInput.createFromTemplate.title': `\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u304b\u3089\u30de\u30c3\u30d7\u3092\u4f5c\u6210`,
    'mypage.maps.titleInput.createFromTemplate.message': `\u30de\u30c3\u30d7\u30ea\u30b9\u30c8\u306b\u30b3\u30d4\u30fc\u3055\u308c\u307e\u3059\u3002`,
    'mypage.templates.preview.templateTitle': `\u3072\u306a\u5f62\u540d`,
    'mypage.templates.preview.category': `\u30ab\u30c6\u30b4\u30ea\u30fc`,
    'mypage.templates.preview.author': `\u4f5c\u8005`,
    'mypage.templates.preview.publisher': `\u767a\u884c\u5143`,
    'mypage.templates.preview.releaseDate': `\u30ea\u30ea\u30fc\u30b9`,
    'mypage.templates.list.operation.createMap': `\u3053\u306e\u3072\u306a\u5f62\u3092\u4f7f\u3046`,
    'mypage.templates.list.operation.changeInfo': `\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u60c5\u5831\u306e\u5909\u66f4`,
    'mypage.templates.list.operation.delete': `\u524a \u9664`,
    'mypage.templates.infoInput.title.new': `\u65b0\u898f\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8`,
    'mypage.templates.infoInput.title.update': `\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u60c5\u5831\u3092\u5909\u66f4`,
    'mypage.templates.infoInput.title.prompt': `\u30c6\u30f3\u30d7\u30ec\u30fc\u30c8\u540d`,
    'mypage.templates.infoInput.publisher.prompt': `\u767a\u884c\u8005`,
    'mypage.templates.infoInput.author.prompt': `\u4f5c\u8005`,
    'mypage.templates.infoInput.detail.prompt': `\u8a73\u7d30`,
    'mypage.personalSettings.title': `\u500b\u4eba\u8a2d\u5b9a`,
    'mypage.personalSettings.back': `\u30de\u30c3\u30d7\u4e00\u89a7\u30da\u30fc\u30b8\u306b\u623b\u308b`,
    'mypage.personalSettings.userInfomation': `\u30e6\u30fc\u30b6\u30fc\u60c5\u5831`,
    'mypage.personalSettings.password': `\u30d1\u30b9\u30ef\u30fc\u30c9`,
    'mypage.personalSettings.serviceConnections': `\u5916\u90e8\u30b5\u30fc\u30d3\u30b9`,
    'mypage.personalSettings.accountStatus': `\u30a2\u30ab\u30a6\u30f3\u30c8\u306e\u72b6\u6cc1`,
    'mypage.personalSettings.user.changePicture': `\u30d7\u30ea\u30bb\u30c3\u30c8\u30a4\u30e1\u30fc\u30b8\u3092\u9078\u629e`,
    'mypage.personalSettings.user.upload': `\u30e6\u30fc\u30b6\u30fc\u30a4\u30e1\u30fc\u30b8\n\u3092\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9`,
    'mypage.personalSettings.user.lastLogin': `\u6700\u7d42\u30ed\u30b0\u30a4\u30f3`,
    'mypage.personalSettings.user.menberSince': `\u30e1\u30f3\u30d0\u30fc\u6b74`,
    'mypage.personalSettings.user.nickname': `\u30cb\u30c3\u30af\u30cd\u30fc\u30e0`,
    'mypage.personalSettings.user.firstName': `\u540d`,
    'mypage.personalSettings.user.lastName': `\u59d3`,
    'mypage.personalSettings.user.companyName': `\u4f1a\u793e`,
    'mypage.personalSettings.user.busyo': `\u90e8\u7f72`,
    'mypage.personalSettings.user.gyosyu': `\u696d\u7a2e`,
    'mypage.personalSettings.user.syokusyu': `\u8077\u7a2e`,
    'mypage.personalSettings.user.telno': `\u96fb\u8a71`,
    'mypage.personalSettings.user.email': `E-mail`,
    'mypage.personalSettings.user.language': `\u8a00\u8a9e\u8a2d\u5b9a`,
    'mypage.personalSettings.user.timezone': `\u30bf\u30a4\u30e0\u30be\u30fc\u30f3`,
    'mypage.personalSettings.user.save': `\u8a2d\u5b9a\u3092\u4fdd\u5b58`,
    'mypage.personalSettings.user.saveMessage': `\u5909\u66f4\u3092\u4fdd\u5b58\u3057\u307e\u3057\u305f`,
    'mypage.personalSettings.changePicture.title': `\u30d7\u30ea\u30bb\u30c3\u30c8\u30a4\u30e1\u30fc\u30b8\u3092\u9078\u629e`,
    'mypage.personalSettings.password.title': `\u30d1\u30b9\u30ef\u30fc\u30c9\u306e\u5909\u66f4`,
    'mypage.personalSettings.password.currentPassword': `\u73fe\u5728\u306e\u30d1\u30b9\u30ef\u30fc\u30c9`,
    'mypage.personalSettings.password.enterCurrentPassword': ``,
    'mypage.personalSettings.password.passComment': `\u30d1\u30b9\u30ef\u30fc\u30c9\u306f\u534a\u89d2\u82f1\u6570\uff08\u6df7\u5728\uff09\u30678\u6587\u5b57\u4ee5\u4e0a\u306b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'mypage.personalSettings.password.newPassword': `\u65b0\u3057\u3044\u30d1\u30b9\u30ef\u30fc\u30c9`,
    'mypage.personalSettings.password.enterNewPassword': ``,
    'mypage.personalSettings.password.reEnterNewPassword': `\u65b0\u3057\u3044\u30d1\u30b9\u30ef\u30fc\u30c9\u3092\u518d\u5165\u529b`,
    'mypage.personalSettings.password.saveMessage': `\u5909\u66f4\u3092\u4fdd\u5b58\u3057\u307e\u3057\u305f`,
    'mypage.personalSettings.serviceConnections.connect': `\u9023 \u643a`,
    'mypage.personalSettings.serviceConnections.evetnoteDetail': `\u3042\u306a\u305f\u306e\u30ce\u30fc\u30c8\u304b\u3089\u30c6\u30ad\u30b9\u30c8\u3084\u753b\u50cf\u3001WEB\u30af\u30ea\u30c3\u30d7(URL)\u3092\u30a2\u30a4\u30c6\u30e0\u5316\u3057\u3001K-Board\u4e16\u754c\u3067\u518d\u6d3b\u7528\u3057\u307e\u3057\u3087\u3046!`,
    'mypage.personalSettings.serviceConnections.twitterDetail': `\u3042\u306a\u305f\u306e\u30bf\u30a4\u30e0\u30e9\u30a4\u30f3\u304b\u3089\u3042\u306e"\u30c4\u30a4\u30fc\u30c8"\u3092\u53d6\u308a\u8fbc\u307f\u3001K-Board\u3067"\u3064\u3076\u3084\u304d\u306e\u5c55\u958b"\u3092\u8a66\u3057\u3066\u307f\u307e\u3057\u3087\u3046!`,
    'mypage.personalSettings.serviceConnections.dropboxDetail': `\u3042\u306a\u305f\u306eDropbox\u306b\u3042\u308b\u30d5\u30a1\u30a4\u30eb\u9054\u3092\u3001K-Board\u3067"\u305d\u308c\u3089\u306e\u95a2\u4fc2\u6027\u3082\u3001\u307e\u308b\u3054\u3068\u30d5\u30a1\u30a4\u30ea\u30f3\u30b0"\u3057\u3066\u307f\u307e\u3057\u3087\u3046!`,
    'mypage.personalSettings.accountStatus.usage.title': `\u4f7f\u7528\u72b6\u6cc1`,
    'mypage.personalSettings.accountStatus.usage.disk.title': `\u30c7\u30a3\u30b9\u30af`,
    'mypage.personalSettings.accountStatus.usage.disk.max': `\u4e2d\u306e`,
    'mypage.personalSettings.accountStatus.usage.disk.use': `\u3092\u4f7f\u7528`,
    'mypage.personalSettings.accountStatus.usage.map.title': `\u30de\u30c3\u30d7`,
    'mypage.personalSettings.accountStatus.usage.map.unit': `\u30de\u30c3\u30d7`,
    'mypage.personalSettings.accountStatus.usage.map.max': `(\u4eee)  \u4e2d\u306e`,
    'mypage.personalSettings.accountStatus.usage.map.use': `\u3092\u4f7f\u7528`,
    'mypage.personalSettings.accountStatus.upgrade.title': `\u30a2\u30c3\u30d7\u30b0\u30ec\u30fc\u30c9`,
    'mypage.personalSettings.accountStatus.upgrade.comments': `K-Board\u30a2\u30ab\u30a6\u30f3\u30c8\u3092\u30a2\u30c3\u30d7\u30b0\u30ec\u30fc\u30c9\u3059\u308b\u3068\u3001\u30b9\u30c8\u30ec\u30fc\u30b8\u5bb9\u91cf\u304c\u5927\u5e45\u306b\u5897\u3048\u3001\u4f5c\u6210\u3067\u304d\u308b\u30de\u30c3\u30d7\u6570\u306f\u7121\u5236\u9650\u306b\u306a\u308a\u307e\u3059\u3002\nK-Board\u308b\u4e16\u754c\u3092\u5b58\u5206\u306b\u6d3b\u7528\u3057\u307e\u3057\u3087\u3046!`,
    'mypage.personalSettings.accountStatus.upgrade.btnLbl': `\u30d7\u30e9\u30f3\u306e\u8a73\u7d30`,
    'mypage.personalSettings.accountStatus.delete.btnLbl': `\u9000 \u4f1a`,
    'mypage.personalSettings.accountStatus.delete.after.title': `K-Board\u3092\u3054\u5229\u7528\u3044\u305f\u3060\u304d\u3001\u8aa0\u306b\u3042\u308a\u304c\u3068\u3046\u3054\u3056\u3044\u307e\u3057\u305f\u3002`,
    'mypage.personalSettings.accountStatus.team.title': `\u6240\u5c5e\u30c1\u30fc\u30e0`,
    'mypage.personalSettings.accountStatus.team.name': `\u30c1\u30fc\u30e0\u540d`,
    'mypage.personalSettings.accountStatus.team.role': `\u6a29\u9650`,
    'mypage.personalSettings.accountStatus.team.map': `\u30c1\u30fc\u30e0\u5185\u3067\u5229\u7528\u3067\u304d\u308b\u30de\u30c3\u30d7`,
    'mypage.teamSettings.back': `\u30de\u30c3\u30d7\u4e00\u89a7\u30da\u30fc\u30b8\u306b\u623b\u308b`,
    'mypage.teamSettings.teamInfomation': `\u30c1\u30fc\u30e0\u60c5\u5831`,
    'mypage.teamSettings.memberList': `\u30e1\u30f3\u30d0\u30fc\u30ea\u30b9\u30c8`,
    'mypage.teamSettings.addMembers': `\u30e1\u30f3\u30d0\u30fc\u306e\u8ffd\u52a0`,
    'mypage.teamSettings.accountStatus': `\u30a2\u30ab\u30a6\u30f3\u30c8\u306e\u72b6\u6cc1`,
    'mypage.teamSettings.team.createDate': `\u30c1\u30fc\u30e0\u4f5c\u6210\u65e5`,
    'mypage.teamSettings.team.teamName': `\u30c1\u30fc\u30e0\u540d`,
    'mypage.teamSettings.team.companyName': `\u4f1a\u793e\u540d`,
    'mypage.teamSettings.team.about.title': `\u30c1\u30fc\u30e0\u306b\u3064\u3044\u3066`,
    'mypage.teamSettings.team.about.prompt': `\u30c1\u30fc\u30e0\u306e\u7d39\u4ecb\u6587\u3092\u5165\u308c\u3066\u304f\u3060\u3055\u3044\u3002`,
    'mypage.teamSettings.team.save': `\u5909\u66f4\u3092\u4fdd\u5b58`,
    'mypage.teamSettings.team.saveMessage': `\u5909\u66f4\u3092\u4fdd\u5b58\u3057\u307e\u3057\u305f`,
    'mypage.teamSettings.member.header.user': `\u30e6\u30fc\u30b6\u30fc`,
    'mypage.teamSettings.member.header.mapNum': `\u30de\u30c3\u30d7\u6570`,
    'mypage.teamSettings.member.header.leader': `\u30ea\u30fc\u30c0\u30fc`,
    'mypage.teamSettings.member.header.admin': `\uff8c\uff67\uff7c\uff98\uff83\uff70\uff80\uff70`,
    'mypage.teamSettings.member.mapUnit': `\u30de\u30c3\u30d7`,
    'mypage.teamSettings.member.deleteBtnLbl': `\u524a \u9664`,
    'mypage.teamSettings.member.save': `\u5909\u66f4\u3092\u4fdd\u5b58`,
    'mypage.teamSettings.member.saveMessage': `\u5909\u66f4\u3092\u4fdd\u5b58\u3057\u307e\u3057\u305f`,
    'mypage.teamSettings.addMember.usage.title': `\u4f7f\u7528\u72b6\u6cc1`,
    'mypage.teamSettings.addMember.usage.user.title': `\u30e6\u30fc\u30b6\u30fc`,
    'mypage.teamSettings.addMember.usage.user.unit': `\u4eba`,
    'mypage.teamSettings.addMember.usage.user.max': `\u4e2d`,
    'mypage.teamSettings.addMember.usage.user.use': `\u304c\u767b\u9332\u3055\u308c\u3066\u3044\u307e\u3059\u3002`,
    'mypage.teamSettings.addMember.member.title': `\u30e1\u30f3\u30d0\u30fc\u306e\u8ffd\u52a0`,
    'mypage.teamSettings.addMember.member.comments': `\u30c1\u30fc\u30e0\u306b\u62db\u5f85\u3057\u305f\u3044\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u3092\u5165\u529b\u3057\u3066\u304f\u3060\u3055\u3044\u3002`,
    'mypage.teamSettings.addMember.member.batchComments': `\u8907\u6570\u30e1\u30f3\u30d0\u30fc\u3092\u307e\u3068\u3081\u3066\u62db\u5f85\u3057\u307e\u3059\u3002\uff11\u884c\uff11\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9\u3067\u5165\u529b\u3057\u3066\u4e0b\u3055\u3044\u3002\uff085000\u6587\u5b57\u4ee5\u5185\uff09`,
    'mypage.teamSettings.addMember.member.prompt': `\u8ffd\u52a0\u30e1\u30f3\u30d0\u30fc\u306e\u30e1\u30fc\u30eb\u30a2\u30c9\u30ec\u30b9`,
    'mypage.teamSettings.addMember.member.add': `\u8ffd \u52a0`,
    'mypage.teamSettings.addMember.member.complete': `\u62db\u5f85\u30e1\u30fc\u30eb\u306e\u9001\u4fe1\u304c\u5b8c\u4e86\u3057\u307e\u3057\u305f`,
    'mypage.teamSettings.addMember.member.maxMessage': `\u8ffd\u52a0\u53ef\u80fd\u4eba\u6570\u4e0a\u9650\u306b\u9054\u3057\u307e\u3057\u305f`,
    'mypage.teamSettings.addMember.member.maxMessageOption': `\u300c\u30e1\u30f3\u30d0\u30fc\u30ea\u30b9\u30c8\u300d\u304b\u3089\u30e6\u30fc\u30b6\u30fc\u3092\u524a\u9664\u3059\u308b\u3068\u3001\u65b0\u305f\u306b\u30e6\u30fc\u30b6\u30fc\u3092\u8ffd\u52a0\u3059\u308b\u3053\u3068\u304c\u3067\u304d\u307e\u3059\u3002`,
    'mypage.teamSettings.addMember.invite.btnLbl': `\u62db \u5f85`,
    'mypage.teamSettings.addMember.invite.individual': `\u500b\u5225\u62db\u5f85`,
    'mypage.teamSettings.addMember.invite.batch': `\u4e00\u62ec\u62db\u5f85`,
    'mypage.teamSettings.accountStatus.usage.title': `\u4f7f\u7528\u72b6\u6cc1`,
    'mypage.teamSettings.accountStatus.usage.disk.title': `\u30c7\u30a3\u30b9\u30af`,
    'mypage.teamSettings.accountStatus.usage.disk.max': `\u4e2d\u306e`,
    'mypage.teamSettings.accountStatus.usage.disk.use': `\u3092\u4f7f\u7528`,
    'mypage.teamSettings.accountStatus.usage.user.title': `\u30e6\u30fc\u30b6\u30fc`,
    'mypage.teamSettings.accountStatus.usage.user.unit': `\u4eba`,
    'mypage.teamSettings.accountStatus.usage.user.max': `\u4e2d`,
    'mypage.teamSettings.accountStatus.usage.user.use': `\u304c\u767b\u9332\u3055\u308c\u3066\u3044\u307e\u3059\u3002`,
    'mypage.teamSettings.accountStatus.delete.btnLbl': `\u9000 \u4f1a`,
    'mypage.afterDeleting.title': `K-Board\u3092\u3054\u5229\u7528\u3044\u305f\u3060\u304d\u3001\u8aa0\u306b\u3042\u308a\u304c\u3068\u3046\u3054\u3056\u3044\u307e\u3057\u305f\u3002`,
    'mypage.questionnaire.title': `\u9000\u4f1a\uff08\u30a2\u30ab\u30a6\u30f3\u30c8\u306e\u524a\u9664\uff09`,
    'mypage.questionnaire.termination.title': `\u89e3\u7d04\u306e\u7406\u7531\u3092\u304a\u805e\u304b\u305b\u304f\u3060\u3055\u3044\uff08\u8907\u6570\u9078\u629e\u53ef\uff09`,
    'mypage.questionnaire.reason.title': `\u4e0d\u6e80\u70b9\u304c\u3042\u3063\u305f`,
    'mypage.questionnaire.reason.kakaku': `\u4fa1\u683c`,
    'mypage.questionnaire.reason.strage': `\u30b9\u30c8\u30ec\u30fc\u30b8\u5bb9\u91cf`,
    'mypage.questionnaire.reason.attachment': `\u6dfb\u4ed8\u30d5\u30a1\u30a4\u30eb\u306e\u5bb9\u91cf\u5236\u9650`,
    'mypage.questionnaire.reason.exService': `\u5916\u90e8\u30b5\u30fc\u30d3\u30b9\u3068\u306e\u9023\u643a\uff08EVERNOTE,DropBox,YouTube\u306a\u3069\uff09`,
    'mypage.questionnaire.reason.os': `\u5bfe\u5fdcOS`,
    'mypage.questionnaire.reason.browser': `\u5bfe\u5fdc\u30d6\u30e9\u30a6\u30b6`,
    'mypage.questionnaire.reason.security': `\u30bb\u30ad\u30e5\u30ea\u30c6\u30a3\u306e\u4fe1\u983c\u6027`,
    'mypage.questionnaire.reason.item': `\u30a2\u30a4\u30c6\u30e0\u3084\u7dda\u7a2e\u306e\u30d0\u30ea\u30a8\u30fc\u30b7\u30e7\u30f3`,
    'mypage.questionnaire.reason.thumb': `\u30b5\u30e0\u30cd\u30a4\u30eb\u3084\u30d7\u30ec\u30d3\u30e5\u30fc\u6a5f\u80fd`,
    'mypage.questionnaire.reason.slow': `\u52d5\u4f5c\u304c\u9045\u3044`,
    'mypage.questionnaire.reason.instability': `\u52d5\u4f5c\u304c\u4e0d\u5b89\u5b9a`,
    'mypage.questionnaire.reason.noSynchro': `\u540c\u671f\u3057\u306a\u3044`,
    'mypage.questionnaire.reason.ability': `\u6a5f\u80fd\u304c\u8db3\u308a\u306a\u3044`,
    'mypage.questionnaire.reason.functionalityDesired': `\u5e0c\u671b\u3055\u308c\u308b\u6a5f\u80fd\u3092\u6559\u3048\u3066\u304f\u3060\u3055\u3044`,
    'mypage.questionnaire.use.title': `\u4e0b\u8a18\u306e\u5229\u7528\u7528\u9014\u306b\u5408\u308f\u306a\u304b\u3063\u305f`,
    'mypage.questionnaire.use.conference': `\u4f1a\u8b70\u652f\u63f4`,
    'mypage.questionnaire.use.email': `\u30e1\u30fc\u30eb\u306e\u4ee3\u66ff`,
    'mypage.questionnaire.use.organize': `\u500b\u4eba\u306e\u601d\u8003\u6574\u7406`,
    'mypage.questionnaire.use.understand': `\u305d\u3082\u305d\u3082\u5229\u7528\u7528\u9014\u304c\u308f\u304b\u3089\u306a\u304b\u3063\u305f\u3002`,
    'mypage.questionnaire.others.title': `\u305d\u306e\u4ed6`,
    'mypage.questionnaire.others.switched': `\u4ed6\u793e\u30b5\u30fc\u30d3\u30b9\u306b\u4e57\u308a\u63db\u3048\u305f`,
    'mypage.questionnaire.others.switchedServiceName': `\u30b5\u30fc\u30d3\u30b9\u540d\u3092\u6559\u3048\u3066\u304f\u3060\u3055\u3044`,
    'mypage.questionnaire.others.trouble': `\u62db\u5f85\u8005\u3068\u30c8\u30e9\u30d6\u30eb\u304c\u3042\u3063\u305f`,
    'mypage.questionnaire.others.lessFrequently': `\u5229\u7528\u983b\u5ea6\u304c\u5c11\u306a\u304f\u306a\u3063\u305f`,
    'mypage.questionnaire.environment.title': `\u3054\u5229\u7528\u74b0\u5883\u306b\u3064\u3044\u3066\u304a\u805e\u304b\u305b\u304f\u3060\u3055\u3044`,
    'mypage.questionnaire.os.title': `\u30e1\u30a4\u30f3\u3067\u4f7f\u308f\u308c\u305f\u30d1\u30bd\u30b3\u30f3OS`,
    'mypage.questionnaire.os.win': `Windows`,
    'mypage.questionnaire.os.mac': `Mac`,
    'mypage.questionnaire.os.others': `\u305d\u306e\u4ed6`,
    'mypage.questionnaire.browser.title': `\u30e1\u30a4\u30f3\u3067\u4f7f\u308f\u308c\u305f\u30d6\u30e9\u30a6\u30b6`,
    'mypage.questionnaire.browser.ie': `IE`,
    'mypage.questionnaire.browser.firefox': `Firefox`,
    'mypage.questionnaire.browser.chrome': `Google Chrome`,
    'mypage.questionnaire.browser.safari': `Safari`,
    'mypage.questionnaire.browser.others': `\u305d\u306e\u4ed6`,
    'mypage.questionnaire.userNum.title': `\u4e3b\u306b1\u4eba\u3067\u5229\u7528\u3057\u305f`,
    'mypage.questionnaire.userNum.one': `\u4e3b\u306b1\u4eba`,
    'mypage.questionnaire.userNum.multi': `\u4e3b\u306b\u8907\u6570\u4eba`,
    'mypage.questionnaire.option': `\u5177\u4f53\u7684\u306a\u5fa1\u610f\u898b\u3001\u6539\u5584\u8981\u671b\u70b9\u304c\u3042\u308a\u307e\u3057\u305f\u3089\u3001\u662f\u975e\u304a\u805e\u304b\u305b\u4e0b\u3055\u3044\u3002`,
    'mypage.questionnaire.option.prompt': `\u81ea\u7531\u5165\u529b\u6b04`,
    'mypage.questionnaire.lastMessage': `\u3054\u56de\u7b54\u3044\u305f\u3060\u304d\u3001\u3042\u308a\u304c\u3068\u3046\u3054\u3056\u3044\u307e\u3057\u305f\u3002`,
    'mypage.questionnaire.confirm': `\u3053\u306e\u30a2\u30ab\u30a6\u30f3\u30c8\u3092\u524a\u9664\u3059\u308b\u3068\u3001\u3042\u306a\u305f\u304c\u4f5c\u6210\u3057\u305f\u30de\u30c3\u30d7\u306f\u5168\u3066\u524a\u9664\u3055\u308c\u307e\u3059\u3002`,
    'mypage.information.title': `K-Board\u304b\u3089\u306e\u304a\u77e5\u3089\u305b`,
    'mypage.tour.skipTour': `\u3053\u306e\u30de\u30a4\u30da\u30fc\u30b8\u306e\u8aac\u660e\u753b\u9762\u3092\u6b21\u56de\u304b\u3089\u8868\u793a\u3057\u306a\u3044`,
    'search.prompt.editor': `\u30de\u30c3\u30d7\u5185\u304b\u3089\u30a2\u30a4\u30c6\u30e0\u3092\u691c\u7d22`,
    'search.prompt.mypage': `\u53c2\u52a0\u30c1\u30fc\u30e0\u5185\u3092\u691c\u7d22`,
    'search.maps': `\u30de\u30c3\u30d7`,
    'search.sheets': `\u30b7\u30fc\u30c8`,
    'search.items': `\u30a2\u30a4\u30c6\u30e0`,
    'search.map': `\u30de\u30c3\u30d7`,
    'search.sheet': `\u30b7\u30fc\u30c8`,
    'search.item': `\u30a2\u30a4\u30c6\u30e0`,
    'search.team': `\u30c1\u30fc\u30e0`,
    'search.owner': `\u30aa\u30fc\u30ca\u30fc`,
    'search.author': `\u4f5c\u6210\u8005`,
    'search.showAllResults': `\u3082\u3063\u3068\u898b\u308b`,
    'search.lastUpdated': `\u6700\u7d42\u66f4\u65b0:`,
    'search.back': `\u623b\u308b`,
    'search.noResults1': `\u6761\u4ef6\u306b\u4e00\u81f4\u3059\u308b\u9805\u76ee\u304c\u3042\u308a\u307e\u305b\u3093`,
    'search.noResults2': `\u7db4\u308a\u3092\u30c1\u30a7\u30c3\u30af\u3059\u308b\u304b\u3001\u6761\u4ef6\u3092\u5909\u3048\u3066\u518d\u691c\u7d22\u3057\u3066\u307f\u3066\u304f\u3060\u3055\u3044`,
    'editor.header.sheet.label': `\u30b7\u30fc\u30c8`,
    'editor.header.sheet.create': `\u30b7\u30fc\u30c8\u3092\u4f5c\u6210`,
    'editor.header.sheet.copy': `\u30b7\u30fc\u30c8\u3092\u8907\u88fd`,
    'editor.header.sheet.png': `\u30b7\u30fc\u30c8\u3092PNG\u5f62\u5f0f\u3067\u30a8\u30af\u30b9\u30dd\u30fc\u30c8`,
    'editor.header.sheet.pngbyselected': `\u30b7\u30fc\u30c8\u3092PNG\uff08\u7bc4\u56f2\u9078\u629e\uff09\u3067\u30a8\u30af\u30b9\u30dd\u30fc\u30c8`,
    'editor.header.sheet.jpeg': `\u30b7\u30fc\u30c8\u3092JPEG\u5f62\u5f0f\u3067\u30a8\u30af\u30b9\u30dd\u30fc\u30c8`,
    'editor.header.sheet.csv': `\u30b7\u30fc\u30c8\u3092CSV\u5f62\u5f0f\u3067\u30a8\u30af\u30b9\u30dd\u30fc\u30c8`,
    'editor.header.sheet.remove': `\u30b7\u30fc\u30c8\u3092\u524a\u9664`,
    'editor.header.sheet.lock': `\u30b7\u30fc\u30c8\u3092\u30ed\u30c3\u30af`,
    'editor.header.sheet.goodshow': `"Good"\u3092\u8868\u793a`,
    'editor.header.sheet.goodshort': `"Good"\u3092\u77ed\u7e2e\u8868\u793a`,
    'editor.header.sheet.forcedvisiblenewitem': `\u65b0\u3057\u3044\u30a2\u30a4\u30c6\u30e0\u3092\u30cf\u30a4\u30e9\u30a4\u30c8\u8868\u793a`,
    'editor.header.sheet.memo': `\u30e1\u30e2\u3092\u8868\u793a`,
    'editor.header.sheet.lockBaseItem': `\u30d9\u30fc\u30b9\u30a2\u30a4\u30c6\u30e0\u3092\u30ed\u30c3\u30af`,
    'editor.header.sheet.property': `\u30b7\u30fc\u30c8\u30d7\u30ed\u30d1\u30c6\u30a3\u3092\u8868\u793a`,
    'editor.header.edit.label': `\u7de8\u96c6`,
    'editor.header.edit.createItem': `\u30a2\u30a4\u30c6\u30e0\u3092\u4f5c\u6210`,
    'editor.header.edit.createItemFromDoc': `\u30c9\u30ad\u30e5\u30e1\u30f3\u30c8\u304b\u3089\u65b0\u3057\u3044\u30a2\u30a4\u30c6\u30e0\u3092\u4f5c\u6210`,
    'editor.header.edit.createBaseitem': `\u30d9\u30fc\u30b9\u30a2\u30a4\u30c6\u30e0\u3092\u4f5c\u6210`,
    'editor.header.edit.copy': `\u30b3\u30d4\u30fc`,
    'editor.header.edit.cut': `\u30ab\u30c3\u30c8`,
    'editor.header.edit.paste': `\u30da\u30fc\u30b9\u30c8`,
    'editor.header.edit.remove': `\u524a\u9664`,
    'editor.header.display.label': `\u8868\u793a`,
    'editor.header.display.navigator': `\u30ca\u30d3\u3092\u8868\u793a`,
    'editor.header.display.presence': `\u30d7\u30ec\u30bc\u30f3\u30b9\u3092\u8868\u793a`,
    'editor.header.display.goodshow': `"Good"\u3092\u8868\u793a`,
    'editor.header.display.forcedvisiblenewitem': `\u65b0\u3057\u3044\u30a2\u30a4\u30c6\u30e0\u3092\u30cf\u30a4\u30e9\u30a4\u30c8\u8868\u793a`,
    'editor.header.display.forcedvisiblenewitemBefore2D': `2 \u65e5\u524d\u307e\u3067\u3092\u30cf\u30a4\u30e9\u30a4\u30c8`,
    'editor.header.display.forcedvisiblenewitemBefore6D': `6 \u65e5\u524d\u307e\u3067\u3092\u30cf\u30a4\u30e9\u30a4\u30c8`,
    'editor.header.display.showPrintingRangeGuide': `\u5370\u5237\u30ac\u30a4\u30c9\u67a0\u8868\u793a`,
    'editor.newItem.label': `\u65b0\u3057\u3044\u30a2\u30a4\u30c6\u30e0`,
    'editor.newBaseItem.label': `\u65b0\u3057\u3044\u30d9\u30fc\u30b9\u30a2\u30a4\u30c6\u30e0`,
    'editor.navigator.title': `\u30ca\u30d3`,
    'editor.navigator.buttonLabel': `\u5168\u4f53\u8868\u793a`,
    'editor.newItemLine.label': `\u30e9\u30d9\u30eb`,
    'editor.option.label': `\u30aa\u30d7\u30b7\u30e7\u30f3`,
    'editor.option.sortUpdateDate': `\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u65e5\u9806\u3067\u4e26\u3073\u66ff\u3048`,
    'editor.option.sortTitle': `\u30bf\u30a4\u30c8\u30eb\u9806\u3067\u4e26\u3073\u66ff\u3048`,
    'editor.option.removeAll': `\u5168\u524a\u9664`,
    'editor.memo.label.title': `\u624b\u66f8\u304d\u30ce\u30fc\u30c8\u3082CamiApp\u3067\u76f4\u63a5\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u53ef\u80fd\uff01`,
    'editor.memo.label.description': `CamiApp\u306e\u30c0\u30a6\u30f3\u30ed\u30fc\u30c9\u306f\u3053\u3061\u3089\u304b\u3089`,
    'editor.memo.label.download': `\u516c\u5f0f\u30b5\u30a4\u30c8\u30ea\u30f3\u30af`,
    'editor.memo.link.ios': `\u30fbiPhone \u7248`,
    'editor.memo.link.android': `\u30fbAndroid \u7248`,
    'editor.memo.renderer.menu.item': `\u30a2\u30a4\u30c6\u30e0\u5316`,
    'editor.memo.renderer.menu.delete': `\u524a\u9664`,
    'editor.attach.clearPassword': `\u672c\u5f53\u306b\u30d1\u30b9\u30ef\u30fc\u30c9\u3092\u524a\u9664\u3057\u307e\u3059\u304b\uff1f`,
    'editor.external.tooltip': `\u30c0\u30d6\u30eb\u30af\u30ea\u30c3\u30af\u3057\u3066\u30a2\u30a4\u30c6\u30e0\u3092\u4f5c\u6210`,
    'editor.twitter.reload': `\u6700\u65b0\u306e\u30c4\u30a4\u30fc\u30c8\u3092\u8868\u793a`,
    'editor.twitter.error': `K-Board\u3067Twitter\u30a2\u30ab\u30a6\u30f3\u30c8\u3092\u5229\u7528\u3059\u308b\u306b\u306f\u30ed\u30b0\u30a4\u30f3\u3057\u3066\u304f\u3060\u3055\u3044`,
    'editor.twitter.login': `\u2192 Twitter\u306b\u30ed\u30b0\u30a4\u30f3\u3059\u308b`,
    'editor.evernote.notebook': `\u30ce\u30fc\u30c8\u30d6\u30c3\u30af`,
    'editor.evernote.createNewItemFromWebClip': `WEB\u30af\u30ea\u30c3\u30d7\u304b\u3089\u65b0\u3057\u3044\u30a2\u30a4\u30c6\u30e0\u3092\u4f5c\u6210`,
    'editor.evernote.createNewItemFromText': `\u30c6\u30ad\u30b9\u30c8\u304b\u3089\u65b0\u3057\u3044\u30a2\u30a4\u30c6\u30e0\u3092\u4f5c\u6210`,
    'editor.evernote.error': `K-Board\u3067Evernote\u30a2\u30ab\u30a6\u30f3\u30c8\u3092\u5229\u7528\u3059\u308b\u306b\u306f\u30b5\u30a4\u30f3\u30a4\u30f3\u3057\u3066\u304f\u3060\u3055\u3044`,
    'editor.evernote.login': `\u2192 Evernote\u306b\u30b5\u30a4\u30f3\u30a4\u30f3\u3059\u308b`,
    'editor.dropbox.back': `\u3072\u3068\u3064\u4e0a\u3078`,
    'editor.dropbox.error': `K-Board\u3067Dropbox\u30a2\u30ab\u30a6\u30f3\u30c8\u3092\u5229\u7528\u3059\u308b\u306b\u306f\u30ed\u30b0\u30a4\u30f3\u3057\u3066\u304f\u3060\u3055\u3044`,
    'editor.dropbox.login': `\u2192 Dropbox\u306b\u30ed\u30b0\u30a4\u30f3\u3059\u308b`,
    'editor.youtube.search': `\u52d5\u753b\u3092\u691c\u7d22`,
    'editor.youtube.all': `\u3059\u3079\u3066`,
    'editor.youtube.topRated': `\u8a55\u4fa1\u306e\u9ad8\u3044\u52d5\u753b`,
    'editor.youtube.topFavirites': `\u304a\u6c17\u306b\u5165\u308a\u767b\u9332\u306e\u591a\u3044\u52d5\u753b`,
    'editor.youtube.mostViewed': `\u518d\u751f\u56de\u6570\u306e\u591a\u3044\u52d5\u753b`,
    'editor.youtube.mostPopular': `\u4eba\u6c17\u306e\u52d5\u753b`,
    'editor.youtube.mostRecent': `\u65b0\u7740\u52d5\u753b`,
    'editor.youtube.mostDiscused': `\u8a71\u984c\u306e\u52d5\u753b`,
    'editor.youtube.mostResponded': `\u30b3\u30e1\u30f3\u30c8\u306e\u591a\u3044\u52d5\u753b`,
    'editor.youtube.recentlyFeatured': `\u304a\u3059\u3059\u3081`,
    'editor.youtube.uploader': `\u30e6\u30fc\u30b6\u30fc\uff1a`,
    'editor.youtube.viewCount': `\u518d\u751f\u56de\u6570\uff1a`,
    'editor.sheetProperty.sheetProperty': `\u30b7\u30fc\u30c8\u30d7\u30ed\u30d1\u30c6\u30a3`,
    'editor.sheetProperty.background': `\u30d0\u30c3\u30af\u30b0\u30e9\u30a6\u30f3\u30c9`,
    'editor.sheetProperty.createDate': `\u4f5c\u6210\u65e5\uff1a`,
    'editor.sheetProperty.updateDate': `\u6700\u7d42\u66f4\u65b0\u65e5\uff1a`,
    'editor.sheetCopy.keywordPrompt': `\u30de\u30c3\u30d7\u3092\u691c\u7d22`,
    'editor.sheetCopy.message1': `\u30b7\u30fc\u30c8\u306e\u8907\u88fd\u3092\u884c\u3044\u307e\u3059\u3002`,
    'editor.sheetCopy.message2': `\u8907\u88fd\u3055\u308c\u305f\u30b7\u30fc\u30c8\u306f\u30de\u30c3\u30d7\u306e\u672b\u5c3e\u306b\u79fb\u52d5\u3057\u307e\u3059\u3002`,
    'editor.itemPropertiy.base.itemLabel': `\u30a2\u30a4\u30c6\u30e0\u30d7\u30ed\u30d1\u30c6\u30a3`,
    'editor.itemPropertiy.base.baseItemLabel': `\u30d9\u30fc\u30b9\u30a2\u30a4\u30c6\u30e0\u30d7\u30ed\u30d1\u30c6\u30a3`,
    'editor.itemPropertiy.base.createDate': `\u4f5c\u6210\u65e5 :`,
    'editor.itemPropertiy.base.updateDate': `\u6700\u7d42\u66f4\u65b0\u65e5 :`,
    'editor.itemPropertiy.attachfile.label': `\u6dfb\u4ed8\u30d5\u30a1\u30a4\u30eb`,
    'editor.itemPropertiy.attachfile.download': `\u6dfb\u4ed8\u30d5\u30a1\u30a4\u30eb\u306e\u30c0\u30a6\u30f3\u30ed\u30fc\u30c9`,
    'editor.itemPropertiy.attachfile.imageOpen': `\u6dfb\u4ed8\u753b\u50cf\u306e\u30c0\u30a6\u30f3\u30ed\u30fc\u30c9`,
    'editor.itemPropertiy.attachfile.name': `\u30d5\u30a1\u30a4\u30eb\u540d :`,
    'editor.itemPropertiy.attachfile.type': `\u30bf\u30a4\u30d7 :`,
    'editor.itemPropertiy.attachfile.size': `\u30b5\u30a4\u30ba :`,
    'editor.itemPropertiy.password.enter': `\u30d1\u30b9\u30ef\u30fc\u30c9\u3092\u5165\u529b`,
    'editor.itemPropertiy.password.reEnter': `\u30d1\u30b9\u30ef\u30fc\u30c9\u3092\u518d\u5165\u529b`,
    'editor.itemPropertiy.password.entered': `\u30d1\u30b9\u30ef\u30fc\u30c9 : *******`,
    'editor.itemPropertiy.password.setting': `\u30d1\u30b9\u30ef\u30fc\u30c9\u3092\u8a2d\u5b9a`,
    'editor.itemPropertiy.password.remove': `\u30d1\u30b9\u30ef\u30fc\u30c9\u3092\u524a\u9664`,
    'editor.link.buttonLabel': `\u30b7\u30a7\u30a2`,
    'editor.link.snapshot.create': `\u30b7\u30fc\u30c8\u306e\u30b9\u30ca\u30c3\u30d7\u30b7\u30e7\u30c3\u30c8`,
    'editor.link.snapshot.reflesh': `\u30b9\u30ca\u30c3\u30d7\u30b7\u30e7\u30c3\u30c8\u3092\u66f4\u65b0`,
    'editor.link.snapshot.copy': `\u753b\u50cf\u306eURL\u3092\u30b3\u30d4\u30fc`,
    'editor.link.sheetUrl': `\u25a0\u30b7\u30fc\u30c8 URL`,
    'editor.link.sheetUrl.copy': `\u30b7\u30fc\u30c8\u306eURL\u3092\u30b3\u30d4\u30fc`,
    'editor.link.sendSheetUrl': `\u25a0\u5171\u6709\u4e2d\u306e\u30e6\u30fc\u30b6\u30fc\u306b\u30b7\u30fc\u30c8 URL \u3092\u9001\u308b`,
    'editor.link.messagePrompt': `\u8ffd\u52a0\u30e1\u30c3\u30bb\u30fc\u30b8`,
    'editor.link.clear': `\u30af\u30ea\u30a2`,
    'editor.link.send': `\u9001 \u4fe1`,
    'editor.link.complete': `\u30e1\u30fc\u30eb\u306e\u9001\u4fe1\u304c\u5b8c\u4e86\u3057\u307e\u3057\u305f\u3002`,
    'editor.lock.buttonLabel': `\u30b7\u30fc\u30c8\u30ed\u30c3\u30af\u4e2d`,
    'editor.itemCreateAssist.create': `\u30a2\u30a4\u30c6\u30e0\u751f\u6210`,
    'editor.itemCreateAssist.prompt': `\u30c6\u30ad\u30b9\u30c8\u3092\u5165\u529b\uff088000\u6587\u5b57\u4ee5\u5185 \u6700\u5927100\u30a2\u30a4\u30c6\u30e0\uff09`,
    'editor.analogical.TitleLabel': `\u95a2\u9023\u306e\u30a2\u30a4\u30c6\u30e0\u304c\u898b\u3064\u304b\u308a\u307e\u3057\u305f`,
    'editor.analogical.noLabel': `\u6761\u4ef6\u306b\u4e00\u81f4\u3059\u308b\u30a2\u30a4\u30c6\u30e0\u304c\u3042\u308a\u307e\u305b\u3093`,
    'editor.itemMenu.shape': `\u30a2\u30a4\u30c6\u30e0\u306e\n\u30ab\u30bf\u30c1\u3092\u5909\u66f4`,
    'editor.itemMenu.style': `\u30a2\u30a4\u30c6\u30e0\u306e\n\u8272\u3092\u5909\u66f4`,
    'editor.itemMenu.text': `\u30c6\u30ad\u30b9\u30c8\u30b5\u30a4\u30ba\u3001\n\u6587\u5b57\u4f4d\u7f6e\u3092\u5909\u66f4`,
    'editor.itemMenu.download': `\u30d5\u30a1\u30a4\u30eb\u3092\u30c0\u30a6\u30f3\u30ed\u30fc\u30c9\u3059\u308b`,
    'editor.itemMenu.file': `\u30d5\u30a1\u30a4\u30eb\u3092Up\u3059\u308b\n\uff08\u6ce8\uff1a\u30c6\u30ad\u30b9\u30c8\u306f\u30d5\u30a1\u30a4\u30eb\n\u540d\u306b\u7f6e\u304d\u63db\u308f\u308a\u307e\u3059\uff01\uff09`,
    'editor.itemMenu.externalSource': `\u30bd\u30fc\u30b7\u30e3\u30eb\u30b5\u30fc\u30d3\u30b9\n\u304b\u3089\u60c5\u5831\u3092\u53d6\u5f97`,
    'editor.itemMenu.property': `\u30d7\u30ed\u30d1\u30c6\u30a3\n\uff08\u30d5\u30a1\u30a4\u30eb\u30d1\u30b9\u30ef\u30fc\u30c9\u8a2d\u5b9a\uff09`,
    'editor.tour.skipTour': `\u6b21\u56de\u8d77\u52d5\u304b\u3089\u306f\u3053\u306e\u753b\u9762\u3092\u8868\u793a\u3057\u306a\u3044`,
    'editor.c0001': `シートを削除します。シート内のデータはすべて削除され、\n復旧はできません。よろしいですか？`,
    'editor.c0002': `\u672c\u5f53\u3067\u3059\u304b\uff1f`,
    'editor.c0003': `\u3053\u306e\u30d5\u30a1\u30a4\u30eb\u306f\u300c\u6697\u53f7\u5316\u300d\u7b49\u306b\u3088\u3063\u3066\u3001\u30a6\u30a3\u30eb\u30b9\u30c1\u30a7\u30c3\u30af\u3067\u304d\u307e\u305b\u3093\u3067\u3057\u305f\u3002 \u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3057\u307e\u3059\u304b\uff1f`,
    'editor.c0004': `\u3053\u306e\u30d5\u30a1\u30a4\u30eb\u306f\u300c\u6697\u53f7\u5316\u300d\u7b49\u306b\u3088\u308a\u30a6\u30a3\u30eb\u30b9\u30c1\u30a7\u30c3\u30af\u3067\u304d\u307e\u305b\u3093\u3067\u3057\u305f\u3002\u5371\u967a\u6027\u304c\u3042\u308a\u307e\u3059\u304c\u3001 \u30c0\u30a6\u30f3\u30ed\u30fc\u30c9\u3057\u307e\u3059\u304b\uff1f`,
    'editor.e0001': `\u901a\u4fe1\u306b\u5931\u6557\u3057\u307e\u3057\u305f\u3002`,
    'editor.e0002': `最新のシート情報を取得するため、ページを再度読み込みます。`,
    'editor.e0003': `\u30a6\u30a3\u30eb\u30b9\u3092\u767a\u898b\u3057\u307e\u3057\u305f! \u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3092\u4e2d\u6b62\u3057\u307e\u3059!`,
    'editor.w0001': `100MB\u4ee5\u4e0a\u306e\u30d5\u30a1\u30a4\u30eb\u3092\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3059\u308b\u3053\u3068\u306f\u3067\u304d\u307e\u305b\u3093\u3002`,
    'editor.w0002': `\u30d5\u30a1\u30a4\u30eb\u66f8\u304d\u51fa\u3057\u30a8\u30e9\u30fc\u3002`,
    'editor.w0003': `\u30d5\u30a1\u30a4\u30eb\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u30a8\u30e9\u30fc\u3002`,
    'editor.w0004': `\u30d5\u30a1\u30a4\u30eb\u540d\u306b\u300c~\u300d\u306e\u542b\u307e\u308c\u308b\u30d5\u30a1\u30a4\u30eb\u306f\u30a2\u30c3\u30d7\u30ed\u30fc\u30c9\u3067\u304d\u307e\u305b\u3093\u3002`,
    "user.role.error": `\u6a29\u9650\u304c\u5909\u66f4\u3055\u308c\u307e\u3057\u305f\u3002\u3053\u306e\u30e6\u30fc\u30b6\u3067\u306f\u64cd\u4f5c\u3067\u304d\u307e\u305b\u3093\u3002`
})