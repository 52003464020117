import React from 'react';
import './PreloadDisplay.css';

function PrealoadDisplay() {

    return (
        <div className="loading-w" >
            <div className="loading-ct text-center">
                <div className="loading-logo">
                    <div className="incon-container">
                    </div>
                </div>
                <div className="loading-txt">
                </div>
                {/* <div class="loading-number text-white font-din-light">089%</div>
                <div class="loading-number font-din-light">LOADING...</div> */}
            </div>
        </div>
    )
}

export default PrealoadDisplay;